@media screen and (min-width:1366px) {
    .svg-wrap {
        height: 100%;
    }

    // .donut-content .svg-wrap{
    //     transform: scale(0.7);
    //     top: -40px;
    //     position: relative;

    //   }
    .region-main app-select-countries .selection {
        margin-top: 30px;
    }

    .ruBenchmarkPage {
        .selection {
            margin-top: 3px !important;
        }
    }

    .custom-tab .mat-tab-label .mat-tab-label-content .mat-title-wrap.mat-title-selected~span {
        font-size: 12px;
    }

    app-tower-analysis.tower-analysis .section {
        overflow: hidden;

        .custom-slider {
            .title {
                font-size: 12px !important;
                font-weight: 900 !important
            }
        }
    }

    .roles-table {

        // .ag-body-viewport{
        //     height: calc(100vh - 487px);
        // }
        .ag-row {
            font-weight: 500;
            font-size: 12px;
        }

        .input-form-control {
            font-weight: 400;
            font-size: 0.75rem;
        }
    }

    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 270px)/(20/11));
            }
        }
    }

    .roles-list-component {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 42px);
                max-height: calc((100vh - 270px)/(20/11));
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .tscroll {
        .form-control {
            font-size: 0.75rem;
            font-weight: 400;
            height: 55px;
        }

        .bar {
            height: 55px !important;
        }
    }

    .table-cell {
        font-weight: 400 !important;
    }

    .search-content {
        font-size: 12px;
    }

    .custom-tab {
        .card-wrap-div {
            flex-basis: 30%;
        }

        .region-chart-wrap {
            .region-wrap {
                #map.view {
                    width: 85% !important;
                    // height: calc(100vh - 195px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .custom-tree {
            .mat-tree {
                height: auto;
            }
        }
    }

    .tower-subtower-modal {
        .mat-dialog-content {
            overflow: hidden;

            .custom-tree {
                .mat-tree {
                    height: auto;
                }
            }
        }
    }

    .brand-card {
        .brand-card-container {
            height: 100%;
        }
    }

    .category-wrap {
        .category-main {
            .custom-tree {
                .mat-tree {
                    height: auto;
                }
            }
        }
    }

    .region-wrap div#map.view {
        padding: 10px 0px;
        width: 250px !important;
    }

    .region-main {
        .region-content {
            max-height: inherit !important;

            .region-wrap {
                height: 165px;
            }

            .ruBenchmarkPage {
                height: 40px;
                min-height: 40px;
            }
        }
    }

    .dealSelectedValue .dealParameterSelection .dealParameterSelect .mat-form-field-flex {
        max-width: 115px;
    }
}

@media screen and (max-width: 1500px) {
    .truncate-regionName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 300px !important;
        max-width: 354px;
    }


}

@media (max-width: 1366.99px) {

    // .mat-chip-list-wrapper {        
    //     padding-top: 30px !important;
    // }
    .region-main app-select-countries .selection {
        margin-top: 30px;
    }

    .custom-tab .area-card .mat-card-title {
        font-size: 12px !important;
    }

    .category-wrap .category-main .donut-content .svg-wrap {
        transform: scale(0.7);
        top: -40px;
        position: relative;

    }

    .custom-tab .mat-chip-list-wrapper {
        padding-top: 10px !important;
    }

    .region-main .region-content .ruBenchmarkPage {
        height: 40px;
        min-height: 40px;
    }

    .region-main .region-content .ruBenchmarkPage .mat-chip-list-wrapper {
        padding-top: 5px !important;
    }

    .customFlex div:first-child span {
        width: 140px;
    }

}

@media screen and (max-width: 1366.99px) and (max-height: 768px) {
    .blue-bg-div {
        .banner-title {
            .banner-count {
                font-size: 100px;
            }

            .title-highlight {
                font-size: 20px !important;
            }

            .title-services {
                font-size: 22px !important;
            }
        }

        .banner-text {
            -webkit-line-clamp: 2 !important;
        }
    }

    .brand-card {
        .brand-cart-header {
            img {
                width: 100px !important;
            }

            .adv-handbook-title {
                font-size: 14px !important;
                font-weight: 600 !important;
                margin-top: 5px !important;
            }
        }

        li {
            padding: 0 !important;

            &::after {
                height: 45px !important;
                top: 60px !important;
            }

            span {
                font-size: 11px !important;
                margin-bottom: 10px !important;
            }
        }

        .brand-card-container {
            img {
                width: 45px !important;
            }
        }
    }

    .custom-tab {
        .card-wrap {
            .card-wrap-div {
                flex-basis: 30%;

                img {
                    width: 70px;
                    bottom: 68px !important;
                }

                .mat-card.area-card {
                    min-height: 160px;
                }
            }
        }

        div#map.view {
            padding-top: 15px !important;
            width: 100% !important;
        }

        .mat-tab-label-content {
            .mat-title-selected {
                font-size: 12px !important;
            }
        }

        .custom-tree {
            .mat-tree {
                height: auto;
            }
        }
    }

    .bechmark-spend-saving-box {
        height: 165px !important;
        padding: 5px !important;

        .blur-bg {
            height: 165px !important;

            button {
                padding: 5px 15px !important;
            }
        }

        .bechmark-spend-saving {
            font-size: 16px !important;

            h4 {
                font-size: 14px !important;
            }

            h6 {
                font-size: 11px !important;

                img {
                    width: 30px !important;
                }
            }

        }
    }

    .bechmark-spend-saving-box.bechmark-spend-saving-modal {
        height: auto !important;
    }

    .savings-main {
        .savings-main {
            .savings-overlay {
                height: 165px !important;
                padding: 5px !important;

                .blur-bg {
                    height: 165px !important;
                }
            }
        }
    }

    .tower-analysis {
        .custom-slider {
            height: 90px !important;
        }
    }

    .notetext {
        bottom: 3px !important;
    }

    .tscroll {
        .form-control {
            font-size: 0.75rem !important;
        }
    }

    .category-wrap {
        .category-main {
            .donut-content {
                height: calc(100vh - 480px);
                overflow: hidden;
            }

            .custom-tree {
                height: calc(100vh - 480px);

                .mat-tree {
                    height: auto;
                }
            }
        }
    }

    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 270px)/(20/11));
            }
        }
    }

    .roles-list-component {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 42px);
                max-height: calc((100vh - 270px)/(20/11));
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .dealSelectedValue .dealParameterSelection .dealParameterSelect .mat-form-field-flex {
        max-width: 115px !important;
    }
}

@media screen and (max-width: 1360px) {
    .truncate-regionName-main {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 320px !important;
        max-width: 400px;
    }

}

@media screen and (max-width:1240px) {
    .button-list li .mat-stroked-button {
        min-width: 25px;
        border: 1px solid #E8E8E8 !important;
    }

    .download-btn-span {
        display: none;
    }

    .download-icon-show {
        display: block !important;
    }

    .truncate-regionName {
        width: auto !important;
        max-width: 200px;
    }

    .popup-slecting-more {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260px;
    }

    .truncate-regionName-main {
        width: auto !important;
        max-width: 275px;
    }
}

@media screen and(max-width:1115px) {
    .downloadInProgress {
        opacity: 0;
    }

    .popup-slecting-more {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 240px;
    }

    .truncate-regionName-main {
        width: auto !important;
        max-width: 230px;
    }
}

@media screen and (max-width:1040px) {

    .region-modal .mat-dialog-content,
    .tower-subtower-modal .mat-dialog-content,
    .detail-tower-modal .mat-dialog-content,
    .variance-spread-modal .mat-dialog-content {
        max-height: 0px !important;
    }

    .popup-slecting-more {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .truncate-regionName-main {
        width: auto !important;
        max-width: 200px;
    }
}

@media screen and (min-width: 1440px) and (max-height: 900px) {

    .custom-tab {
        div#map.view {
            // padding-top: 60px !important;
            width: 100% !important;
        }

        .select-options {
            height: calc(100vh - 404px);
        }

        .mat-chip-list-wrapper {
            height: 140px;
        }
    }

    .donut-content {
        .custom-tree .mat-tree {
            height: auto;
        }

        // .custom-tree{
        //     height: calc(100vh - 395px);
        // }
    }

    .category-wrap {
        .category-main {
            .donut-content {
                height: calc(100vh - 479.5px);

                .col-8.chart-wrap {
                    display: inline-block;
                    padding-top: 0px !important;
                }

                .custom-tree {
                    height: calc(100vh - 479.5px);

                    .mat-tree {
                        height: auto;
                    }
                }
            }
        }
    }

    .custom-slider~.title {
        margin-top: 40px !important;
    }

    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 270px)/(20/11));
            }
        }
    }

    .roles-list-component {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 270px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 270px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 270px)/(20/11)) - 42px);
                max-height: calc((100vh - 270px)/(20/11));
                transition: all 0.2s ease-in-out;
            }
        }
    }

}

@media screen and (min-width:1600px) and (max-height: 1200px) {
    .mat-dialog-content {
        .donut-content {

            // height: calc((100vh - 260px) / 1.8181818182) !important;
            .custom-tree .mat-tree {
                height: auto;
            }

            .col-8.chart-wrap {
                display: inline-block;
                padding-top: 30px !important;
            }

            // .custom-tree{
            //     height: calc(100vh - 395px);
            // }
        }
    }

}

@media screen and (min-width:1680px) and (max-height: 1050px) {
    .tower-subtower-modal .svg-wrap {
        height: inherit;
    }

    .category-wrap .category-main .svg-wrap {
        height: 100%;
    }

    .mat-dialog-content {
        .donut-content {

            // height: calc((100vh - 235px) / 1.8181818182) !important;  
            .custom-tree .mat-tree {
                height: auto;
            }

            .col-8.chart-wrap {
                display: inline-block;
                padding-top: 30px !important;
            }
        }
    }

    // .roles-table{
    //     .ag-body-viewport{
    //         height: calc((100vh - 135px) / 1.8181818182);
    //     }
    // } 
    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 120px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 120px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 120px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 120px)/(20/11));
            }
        }
    }

    .roles-list-component {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 120px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 120px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 120px)/(20/11)) - 42px);
                max-height: calc((100vh - 120px)/(20/11));
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .price-main .section {
        height: 45vh;
    }

    .category-wrap {
        .category-main {
            .donut-content {
                height: calc(100vh - 479.5px);
            }

            .custom-tree {
                height: calc(100vh - 479.5px);

                .mat-tree {
                    height: auto;
                }
            }
        }
    }

    .tscroll {
        height: calc(55vh - 200px);
    }
}


@media screen and (min-width: 1920px) and (max-height: 1080px) {
    .user-last-login {
        font-size: 13px !important;
    }

    .blue-bg-div {
        .banner-text {
            font: normal normal 300 18px/28px "Open Sans" !important;
            padding-left: 95px;

            &::before {
                bottom: 52px !important;
            }
        }

        .banner-count {
            font: normal normal 600 150px/175px "Open Sans";
        }

        .banner-title-text {
            font: normal normal 300 25px/41px "Open Sans";
        }

        .title-highlight {
            font: normal normal 800 30px/40px "Open Sans";
        }

        .banner-arrow-btn {
            bottom: 25px;
            right: 15px;
        }
    }

    .brand-card {
        li {
            &::after {
                height: 95px !important;
                top: 95px !important;
            }

            span {
                font-size: 15px !important;
            }
        }

        .brand-card-container {
            img {
                width: 75px !important;
                margin-top: 20px !important;
            }
        }
    }

    .benchmark {
        .desc {
            font-size: 16px !important;
            min-height: 110px;
        }

        .title {
            font-size: 24px !important;
        }

        .carousel-indicators {
            margin-left: 20px;
            bottom: 0px;
        }

        .carousel-center {
            min-height: 220px;
        }
    }

    .custom-tab {
        .card-wrap {
            .mat-card {
                .mat-card-title {
                    font-size: 14px;
                }

                min-width: 545px;
                min-height: 445px;
                justify-content: space-between;

                img {
                    width: 300px !important;
                }
            }

            .card-wrap-div {
                .mat-card {
                    min-width: 275px;
                    min-height: 250px;

                    img {
                        width: 100px !important;
                    }

                    .mat-card-title {
                        padding: 25px 15px;
                    }
                }
            }
        }

        .title-full-form {
            font-size: 14px !important;
        }

        .mat-tab-label-content {
            font-size: 14px !important;
        }

        .select-options li {
            font-size: 14px;
        }

        .selection {
            .bg-heading .title {
                font-size: 14px !important;
            }
        }

        .custom-tree {
            .mat-checkbox-layout {
                .mat-checkbox-label {
                    font-size: 14px;
                }
            }
        }

        div#map.view {
            padding-top: 90px !important;
            width: 100% !important;
        }

        .select-options {
            height: calc(100vh - 404px);
        }

        .mat-chip-list-wrapper {
            height: 140px;
        }

        .category-main .bg-heading .title {
            font-size: 14px;
        }

        .category-wrap {
            .mat-checkbox-label {
                font-size: 13px !important;
            }
        }
    }

    // .roles-table{
    //     .ag-body-viewport{
    //         height: calc((100vh - 150px) / 1.8181818182) !important;
    //     }
    // } 
    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 150px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 150px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 150px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 150px)/(20/11));
            }
        }
    }

    .app-opportunity-assessment-new {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 150px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 150px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 150px)/(20/11)) - 30px);
                transition: all 0.2s ease-in-out;
                max-height: calc((100vh - 150px)/(20/11));
            }
        }
    }

    .roles-list-component {
        .roles-table {
            .ag-body-viewport {
                height: calc((100vh - 150px) / 1.8181818182);
            }
        }

        .roles-table.totalSpendActive {
            max-height: calc(((100vh - 150px)/(20/11)) + 38px);

            .ag-body-viewport {
                height: calc(((100vh - 150px)/(20/11)) - 42px);
                max-height: calc((100vh - 150px)/(20/11));
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .category-wrap {
        .donut-content {
            height: calc(100vh - 480px) !important;
        }

        // .mat-checkbox-layout{
        //     .mat-checkbox-label{
        //         font-size: 12px !important; 
        //     }
        // }           
        .custom-tree {
            height: calc(100vh - 480px) !important;

            .mat-tree {
                height: auto;
            }
        }
    }

    .category-main {
        .bg-heading {
            .title {
                font-size: 14px;
            }
        }
    }

    .price-main {
        .section {
            height: 45.8vh;
        }
    }

    .toggle-btn {
        font-size: 15px !important;
    }

    .search-content {
        font-size: 13px !important;
    }

    .bechmark-spend-saving-modal {
        .bechmark-spend-saving {
            padding-right: 185px !important;
        }
    }

    .button-list li {
        .mat-stroked-button {
            .mat-button-wrapper {
                font-size: 13px;
            }
        }
    }

    .detail-tower-modal {
        .chart-container {
            height: 350px !important;
        }

        .mat-dialog-content {
            max-height: 630px !important;
        }
    }

    .custom-slider {
        height: 145px !important;
    }

    .selected-loc-title {
        font-size: 13px !important;
    }

    .mat-dialog-content {
        .donut-content {
            .category-main .bg-heading .title {
                font-size: 14px !important;
            }

            .custom-tree .mat-tree {
                height: auto;
            }

            .col-8.chart-wrap {
                display: inline-block;
                padding-top: 0px !important;
            }

            // .custom-tree{
            //     height: calc(100vh - 395px);
            // }
        }
    }

    .region-modal .mat-dialog-content {
        min-height: 650px !important;

        div#mapModal.view {
            padding-top: 30px;
        }

        .map-wrap {
            border-right: 1px solid #F3F3F3;
        }

        .selection {
            border-left: 1px solid transparent;
        }

        .select-options {
            height: 395px !important
        }
    }

    .app-general-price-bar {
        .custom-select-filter span {
            font-size: 12px !important;
        }
    }

    .right-col-inner {
        .oa-selections {
            .custom-select-filter label {
                font-size: 12px !important;
            }

            .custom-select label {
                font-size: 12px !important;
            }
        }
    }

}

@media (-webkit-device-pixel-ratio: 1.50) {

    // :root {
    //   zoom: 0.9;
    // }
    //   .price-bar{
    //     transform: scale(0.6);
    //   }
    .role-label .mat-icon {
        top: 15px !important;
    }

    .dealparameters {
        padding: 4px 0px !important;
        height: 70px !important;
    }

    .saved-scenario-section {
        margin-bottom: 15px !important;
    }

    .region-modal .mat-dialog-content,
    .tower-subtower-modal .mat-dialog-content,
    .detail-tower-modal .mat-dialog-content,
    .variance-spread-modal .mat-dialog-content {
        max-height: 75vh !important;
    }

    .region-modal .section .select-options {
        height: 195px !important;
    }

    mat-dialog-content .donut-content .svg-wrap {
        top: 0px !important;
    }

    .category-wrap .donut-content .svg-wrap {
        transform: scale(0.7);
        top: -53px !important;
        position: relative;
    }

    .mat-dialog-content .donut-content .svg-wrap {
        transform: scale(1);
        top: 0;
    }

    .custom-tab app-tower-sunburst .svg-wrap,
    .custom-tab .chart-wrap .svg-wrap {
        transform: scale(0.9);
        top: -15px;
    }

    .custom-tree .mat-checkbox-layout .mat-checkbox-label,
    .title {
        font-size: 11px !important;
    }

    .headings,
    .dealSelectedValue .dealParameterSelection .dealParameterName,
    .selection .tags,
    .search-content,
    .roles-table .ag-row,
    .roles-table .ag-header-row,
    .dealSelectedValue .dealParameterSelection .dealParameterSelect .mat-form-field-infix {
        font-size: 11px !important;
    }

    .tower-params-component .disp-flex {
        max-height: 425px !important;
    }

    .roles-list-component .roles-table .ag-body-viewport {
        height: calc((100vh - 292px) / 1.8181818182);
    }

    .brand-card .brand-card-container img {
        width: 30px !important;
    }

    .brand-card li span {
        font-size: 10px !important;
    }

    .brand-card li::after {
        top: 20px !important;
    }

    .carousel-center .theme-btn {
        bottom: 15px !important;
    }

    .blue-bg-div.dashboard-bg .banner-text::before {
        bottom: 27px !important
    }

    .blue-bg-div .banner-text {
        font: normal normal 300 15px/20px "Open Sans" !important;
    }

    .carousel-center {
        padding: 15px 10px !important;
    }

    .tower-selection {
        padding: 10px 10px !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .donut-content {

        app-tower-sunburst,
        .chart-wrap {
            height: 145px;
            overflow: hidden;

            .svg-wrap {
                padding: 15px !important;

                #towerSunburst {
                    transform: scale(0.9) !important;
                }
            }

        }
    }

    .category-wrap .category-main .donut-content,
    .category-wrap .category-main .custom-tree {
        height: calc(100vh - 450px) !important;
    }

    .pyr-main .custom-table .table-body {
        height: 60px !important;
    }

    .price-main .section {
        overflow: hidden !important;
    }

    app-tower-subtower-selector {

        app-tower-sunburst,
        .chart-wrap {
            height: inherit !important;
            overflow: auto !important;

            .svg-wrap {
                padding: 15px !important;

                #towerSunburst {
                    transform: scale(1) !important;
                }
            }

        }
    }

    .custom-tab .area-card .mat-card-title {
        font-size: 12px !important;
    }

    .custom-tab div#map.view {
        width: 80% !important;
    }

    .custom-tab {

        app-tower-sunburst,
        .chart-wrap {
            height: 400px !important;
            overflow: hidden !important;

            .svg-wrap {
                padding: 15px !important;

                #towerSunburst {
                    transform: scale(1) !important;
                }
            }

        }
    }

    // .mat-chip-list-wrapper {
    //     padding-top: 15px !important;
    // }
    .region-main .selection .mat-chip-list-wrapper {
        padding-top: 28px !important;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    header {
        display: none !important;
    }

    .sidebar {
        display: none !important;
    }

    app-tower-subtower-list {
        display: none;
    }

    #towerSunburst {
        width: 500px;
        height: 500px;
    }

    .mat-checkbox-inner-container {
        display: none;
    }
}

//   @media (-webkit-device-pixel-ratio: 1.75) {
//     .donut-content .svg-wrap{
//         transform: scale(0.6);
//         top: -53px;
//         position: relative;
//       }
//       .custom-tree .mat-checkbox-layout .mat-checkbox-label, .title{
//         font-size:11px !important;
//       }
//       .headings, .dealSelectedValue .dealParameterSelection .dealParameterName, .selection .tags, .search-content, .roles-table .ag-row, .roles-table .ag-header-row, .dealSelectedValue .dealParameterSelection .dealParameterSelect .mat-form-field-infix{
//         font-size: 11px !important;
//       } 
//       .tower-params-component .disp-flex{
//         max-height: 425px !important;
//       }
//     .roles-list-component .roles-table .ag-body-viewport {
//         height: calc((100vh - 292px) / 1.8181818182);
//     }
//     .brand-card .brand-card-container img {
//         width: 30px !important;
//     }
//     .brand-card li span{
//         font-size: 11px !important;
//     }
//     .brand-card li::after {        
//         top: 20px !important;
//     }
//     .carousel-center .theme-btn{
//         bottom: 15px !important;
//     }
//     .carousel-center {
//         padding: 15px 10px !important;
//     }
//   }