/* You can add global styles to this file, and also import other style files */


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hexindex4-primary: mat.define-palette(mat.$indigo-palette);
$hexindex4-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hexindex4-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hexindex4-theme: mat.define-light-theme((color: (primary: $hexindex4-primary,
        accent: $hexindex4-accent,
        warn: $hexindex4-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hexindex4-theme);

/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Inter:wght@200;300;400;500;600&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'shepherd.js/dist/css/shepherd.css';
@import 'media.scss';

.df-jcsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pt-15 {
  padding-top: 15px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-7 {
  padding-right: 7px;
}

.pl-7 {
  padding-left: 7px;
}

.plr-7 {
  padding: 0 7px;
}

.p-0 {
  padding: 0;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mb5 {
  margin-bottom: 5px;
}

.m0 {
  margin: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-15 {
  margin-bottom: 15px;
}

.plr-15 {
  padding: 0 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.icon {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.icons-image {
  height: 28px;
  width: 28px;
  display: inline-block;
  background-image: url('assets/img/icons-image.svg');
}

.icon-excel {
  background-position: 0 0;
}

.icon-pdf {
  background-position: -28px 0;
}

.icon-edit {
  background-position: -56px 0px;
}

.icon-chevron-right {
  background-position: -84px 0;
}

.icon-dashboard {
  background-position: -168px 0;
}

.icon-cancel {
  background-position: -336px 0;
}

.icon-placeholder {
  background-position: -392px 0;
}

.icon-logout {
  background-position: -420px 0;
}

.icon-menu {
  background-position: -448px 0;
}

.icon-notification {
  background-position: -476px 0;
}

.icon-arrow-right {
  background-position: -504px 0;
}

.icon-reset {
  background-position: -532px 0;
}

.icon-save {
  background-position: -560px 0;
}

.icon-user {
  background-position: -588px 0;
}

.icon-info {
  background-position: -618px 0;
}

.icon-next {
  background-position: -646px 0;
}

.icon-cancel-white {
  background-position: -674px 0;
}

.icon-check-white {
  background-position: -702px 0;
}

.icon-selected {
  background-position: -730px 0;
}

.icon-add-green {
  background-position: -765px 0;
}

.icon-fte {
  background-position: -793px 0;
}

.icon-ru {
  background-position: -821px 0;
}

.icon-pdf-black {
  background-position: -849px 0;
}

.icon-expand {
  background-position: -905px 0;
}

.icon-info-border {
  background-position: -933px 0;
}

.icon-reset-small {
  background-position: -961px 0;
}

.icon-more {
  background-position: -997px 0;
}

.icon-double-arrow {
  background-position: -1025px 0;
}

.icon-check-green {
  background-position: -1035px 0;
}

.icon-info-new {
  background-position: -1147px 0;
}

.icon-ito {
  background-position: 0 -30px;
}

.icon-bpo {
  background-position: -200px -30px;
}

.icon-adm {
  background-position: -406px -28px;
}

.icon-infra {
  background-position: -400px -68px;
}

.icon-consulting {
  background-position: -400px -108px;
}

.icon-healthcare {
  background-position: -400px -108px;
}

.icon-hro {
  background-position: -400px -148px;
}

.icon-fao {
  background-position: -400px -198px;
}

.icon-supply-chain {
  background-position: -400px -248px;
}

.icon-contact-center {
  background-position: -400px -298px;
}

.icon-digital-marketing {
  background-position: -400px -348px;
}

.icon-indian {
  background-position: -462px -28px;
}

.icon-blended {
  background-position: -462px -78px;
}

.icon-global,
.icon-global-si {
  background-position: -462px -128px;
}

.icon-bg-user {
  background-position: -96px -257px;
}

.icon-bg-fte {
  background-position: -192px -257px;
}

.icon-bg-ru {
  background-position: 0 -257px;
}

.icon-dashboard-yellow {
  background-position: -1063px 0;
}

.icon-dashboard-white {
  background-position: -1091px 0;
}

.icon-fte-benchmark-yellow {
  background-position: -1063px -28px;
}

.icon-fte-benchmark-white {
  background-position: -1119px 0;
}

.icon-ru-benchmark-white {
  background-position: -1119px -28px;
}

.icon-ru-benchmark-yellow {
  background-position: -1091px -28px;
}

.icon-add-white {
  background-position: -510px -28px;
}

.icon-download {
  background-position: -538px -28px;
}

.icon-double-arrow-white {
  background-position: -566px -29px;
}

.icon-table-edit {
  background-position: -581px -30px;
}

.icon-box-plot {
  background-position: -601px -30px;
}

.icon-role-grid-dropdown {
  background-position: -621px -30px;
}

.icon-arrow-down {
  background-position: -637px -30px;
}

.icon-save-new {
  background-position: -653px -28px;
}

.icon-reset-new {
  background-position: -669px -28px;
}

.icon-info-light-gray {
  background-position: -685px -28px;
}

.icon-brand-logo {
  background-position: -879px -637px;
}

.icon-brand-logo {
  background-position: -879px -637px;
}

// ----font-----
$font_primary: 'Open Sans', 'Inter', 'Roboto';

// ----font-weight-----

// ----bg_color-----
$blue_bg: #20497D;
$bg_white: #fff;
$bg_gold: #C5AC2E;
$bg_light_gold: #D0B383;
$bg_light_gray: #EFEFEF;
$bg_blue2: #1F487C;
$bg_light_white: #C4CACC;

// ----text_color-----
$text_white: #fff;
$text_light_gray: #333333;
$text_light_gray1: #919191;
$text_blue: #2B95C8;
$text_dark_blue: #000B1C;
$text_black: #000000;
$text_gray: #5D6B70;
$text_gray3: #707070;

// ----border_color-----
$border_gray: #CED4DA;
$border_blue: #20497D;
$border_gray2: #F0F0F0;
$border_topbtn: #C4CACC;

// ----boxshadow_color-----
$box_shadow: rgb(0 0 0 / 14%);
$box_shadow2: rgb(0 0 0 / 10%);
$box_shadow3: rgba(0, 0, 0, 0.2);

$save_hover_btn: #95f195;
$reset_hover_btn: #c8d6eb;


$hex_gold: #C5AC2E;
$hex_gold_highlight: #D9BD10;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font_primary;
  background-color: $bg_white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font_primary;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.scroll-window::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll-window::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-window::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

.scroll-window::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.selection .mat-chip-list-wrapper::-webkit-scrollbar,
.mat-select-panel .mat-chip-list-wrapper::-webkit-scrollbar,
.tscroll::-webkit-scrollbar,
.full-width-grid .ag-body-viewport::-webkit-scrollbar,
.savings-main .section::-webkit-scrollbar,
.price-main .section::-webkit-scrollbar,
.roles-table .ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.selection .mat-chip-list-wrapper::-webkit-scrollbar-track,
.mat-select-panel .mat-chip-list-wrapper::-webkit-scrollbar-track,
.tscroll::-webkit-scrollbar-track,
.full-width-grid .ag-body-viewport::-webkit-scrollbar-track,
.savings-main .section::-webkit-scrollbar-track,
.price-main .section::-webkit-scrollbar-track,
.roles-table .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.selection .mat-chip-list-wrapper::-webkit-scrollbar-thumb,
.mat-select-panel .mat-chip-list-wrapper::-webkit-scrollbar-thumb,
.tscroll::-webkit-scrollbar-thumb,
.full-width-grid .ag-body-viewport::-webkit-scrollbar-thumb,
.savings-main .section::-webkit-scrollbar-thumb,
.price-main .section::-webkit-scrollbar-thumb,
.roles-table .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

.selection .mat-chip-list-wrapper::-webkit-scrollbar-thumb:hover,
.mat-select-panel .mat-chip-list-wrapper::-webkit-scrollbar-thumb:hover,
.tscroll::-webkit-scrollbar-thumb:hover,
.full-width-grid .ag-body-viewport::-webkit-scrollbar-thumb:hover,
.savings-main .section::-webkit-scrollbar-thumb:hover,
.price-main .section::-webkit-scrollbar-thumb:hover,
.roles-table .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.roles-table .ag-header-container,
.roles-table .ag-header-row,
.roles-table .ag-center-cols-container {
  min-width: 100% !important;
}

.m0 {
  margin: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-green {
  background-color: #C5AC2E;
  border-radius: 5px !important;
  color: #fff;
  padding: 2px 10px 0px 0 !important;
  font-size: 13px;
  font-weight: 500;
}

.wrapper {
  width: 100%;
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $bg_white;
  display: block;
  z-index: 1;
}

.header nav {
  width: 100%;
  height: 48px;
  background-color: #555555;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding-left: 5px;
}

.header .header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .header-logo .hamburger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: transparent;
  padding-top: 15px;
}

.hamburger {
  .mat-stroked-button {
    min-width: 48px;
    height: 48px;
    border: 0px solid currentColor;
    padding: 0 0px;
    border-radius: 0px;
    line-height: 0px;
    display: none;
  }
}

.header .header-logo .logo {
  cursor: pointer;
  width: 112px;
  padding: 0px 5px 0 10px;
}

.header .header-icon {
  width: auto;
  padding-right: 12px;
}

.header .header-icon ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  padding-left: 0rem;
}

.header .header-icon ul li {
  list-style: none;
}

.header .header-icon ul li.notifi-icon {
  width: 36px;
  height: 36px;
  position: relative;
  background-color: transparent;
  border-radius: 6px;
  transition: all 0.4s ease;
  display: none;
}

li.notifi-icon {
  .mat-button {
    min-width: 36px;
    height: 36px;
    border: 0px solid currentColor;
    padding: 0 0px;
    border-radius: 0px;
    line-height: 0px;
  }
}

.header .header-icon ul li.user-icon {
  position: relative;
  transition: all 0.4s ease;

  .mat-button {
    min-width: 36px;
    height: 36px;
    border: 0px solid currentColor;
    padding: 0 0px;
    border-radius: 0px;
    line-height: 0px;
  }

  .user-name {
    color: #fff;
    padding: 0 10px 0 0;
    font-size: 12px;
    font-weight: 500;
  }

  .icon-user {
    border-radius: 50%;
    border: 2px solid #fff;
  }
}


/* user-togglediv */
.user-togglediv {
  position: absolute;
  top: 39px;
  right: 0px;
  width: 124px;
  height: 124px;
  background-color: $bg_white;
  z-index: 2;
  border-radius: 3px;
  box-shadow: $box_shadow2 0px 2px 6px;
  display: none;
  transition: all 0.5s ease;
}

.user-togglediv .user-togglediv-ul {
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  padding-left: 0rem;

}

.user-togglediv .user-togglediv-ul li {
  list-style: none;
  width: 100%;
  padding: 4px 12px 4px 12px;
  position: relative;
  transition: all 0.5s ease;
}

.user-togglediv .user-togglediv-ul li:hover {
  background-color: $bg_light_gray;
  transition: all 0.5s ease;
}

.user-togglediv .user-togglediv-ul li a {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 11px;
  line-height: 24px;
  font-family: $font_primary;
  color: $text_dark_blue;
}

.user-togglediv .user-togglediv-ul li a:hover {
  text-decoration: none;
}

.user-togglediv .user-togglediv-ul li.log-out-li {
  background-color: $bg_light_gold;
}

.user-togglediv .user-togglediv-ul li.log-out-li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text_white;
  font-size: 13px;
  line-height: 24px;
  font-family: $font_primary;
}

.mat-menu-panel .mat-menu-item {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  font-family: $font_primary;
  color: $text_dark_blue;
  line-height: 48px;
  height: 35px;
  min-height: 35px !important;
  padding: 0 32px;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    background: #C5AC2E !important;
    color: #fff;
  }
}

.mat-menu-panel .mat-menu-item a {
  color: $text_dark_blue;
}

.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.roles-dropdown {
  .mat-menu-item {
    padding: 0 10px;
  }
}

#logout-btn {
  background-color: $bg_light_gold;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

#logout-btn a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text_white;
  width: 100%;
}

/* notific-div */
.notific-div {
  width: 315px;
  height: auto;
  border-radius: 3px;
  position: absolute;
  top: 40px;
  right: 0px;
  display: none;
  transition: all 0.5s ease;
  background-color: $bg_white;
  box-shadow: $box_shadow 0px 2px 6px;
  z-index: 1000;

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: $text_blue;
    }
  }

  .mat-tab-body-content {
    height: 100%;
    overflow: visible;
    overflow-y: inherit;
    width: 100%;
    padding: 16px 25px;
  }

  .mat-tab-group.mat-primary {
    .mat-ink-bar {
      background-color: $text_blue;
    }
  }
}

.notific-div .tabs {
  width: 100%;
}

.notific-div .tabs ul {
  padding: 0px 12px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.notific-div .tabs ul li {
  padding: 10px 12px 0px 12px;
}

.notific-div .tabs ul li a {
  font-size: 12px;
  line-height: 15px;
  opacity: 80%;
  position: relative;
}

.notific-div .tabs ul li.active a:before {
  content: "";
  position: absolute;
  bottom: -6px;
  width: 100%;
  z-index: 2;
}

.notific-div .tabs ul li.active a {
  color: $text_blue;

}

#tabs-content {
  width: 100%;
  padding: 15px 26px;
}

.notific-div-tab1-ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}

.notific-div-tab1-ul .img {
  width: 26px;
  height: 26px;
  position: relative;
}

.green-img {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.notific-div-tab1-ul .notific-div-tab1-content {
  padding: 5px 10px 0px 11px;
}

.notific-div-tab1-ul .notific-div-tab1-content p {
  font-size: 10px;
  line-height: 12px;
  color: $text_light_gray;
  font-family: $font_primary;
  margin-bottom: 0px;
  opacity: 85%;
  width: 208px;
}

.notific-div-tab1-ul .notific-div-tab1-content p span {
  font-size: 8px;
  line-height: 12px;
  color: $text_light_gray1;
  font-family: $font_primary;
  font-weight: 500;
}


.mat-tab-label-container {
  display: flex;
  flex-grow: 1;
  z-index: 1;
}

.mat-tab-label {
  height: 40px !important;
  padding: 12px 24px 0px !important;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: auto !important;
  text-align: left !important;
  display: inline-flex;
  justify-content: left !important;
  align-items: center;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
  line-height: 15px;
  color: $text_light_gray;
  font-weight: 400;
}

/*left-navbar */
.white-header {
  padding: 0px 45px 0px 15px;

  .white-header-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .toggle-btn-wrap {
      position: absolute;
      left: 34%;
    }
  }
}

/*sidebar*/
.sidebar {
  position: fixed;
  top: 15px;
  left: 0;
  height: 100%;
  width: 250px;
  background: #555555;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  padding: 40px 0 0 0;
}

.sidebar {
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.sidebar::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.sidebar::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

.sidebar .nav-links {
  margin-bottom: 0rem;
  padding-left: 0px;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li img {
  text-align: center;
  line-height: 50px;
}

.sidebar .nav-links li .dashboard-white {
  background-color: transparent;
  width: 100%;
  // height: 36px;
  margin-top: 6px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar .nav-links li .dashboard-white a span.link_name {
  font-size: 13px;
  line-height: 20px;
  color: $text_white;
  font-weight: 500;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 172px;
  position: relative;
  padding-right: 10px;
  transition: all 0.4s ease-in-out;
  font-family: $font_primary;

}

.sidebar .nav-links li .dashboard-white a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
}

.sidebar .nav-links li .dashboard-white a:hover {
  text-decoration: none;
  background-color: $bg_gold;
  transition: all 0.4s ease-in-out;
}

// .sidebar.close .nav-links li .dashboard-white a:hover {
//   background-color: transparent;
// }

.sidebar .nav-links li .dashboard-white a span.img-span {
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  padding-top: 0;
}

.sidebar.close .nav-links li a.active-link .img-span::before {
  content: '';
  width: 6px;
  height: 22px;
  background-color: #C5AC2E;
  position: absolute;
  left: 0;
  top: 3px;
}

.sidebar {
  a {

    .icon-dashboard-yellow,
    .icon-fte-benchmark-yellow,
    .icon-ru-benchmark-yellow {
      display: none;
    }

    .icon-dashboard-white,
    .icon-fte-benchmark-white,
    .icon-ru-benchmark-white {
      display: block;
    }

    // &.active-link {
    //   .icon-dashboard-yellow,
    //   .icon-fte-benchmark-yellow,
    //   .icon-ru-benchmark-yellow {
    //     display: block;
    //   }
    //   .icon-dashboard-white,
    //   .icon-fte-benchmark-white,
    //   .icon-ru-benchmark-white {
    //     display: none;
    //   }
    //   :hover {
    //     .icon-dashboard-yellow,
    //     .icon-fte-benchmark-yellow,
    //     .icon-ru-benchmark-yellow {
    //       display: none;
    //     }
    //     .icon-dashboard-white,
    //     .icon-fte-benchmark-white,
    //     .icon-ru-benchmark-white {
    //     display: block;
    //     }      
    //   }      
    // }
  }


  .link_name {
    ul {
      padding: 0;

      a {
        padding: 5px 10px;
        color: #fff;
        height: 36px;
        align-items: center !important;

        &:hover {
          background-color: #C5AC2E !important;
        }
      }
    }
  }

  .nav-links li .dashboard-white.item-fte a:hover {
    background: transparent;
  }

  .nav-links li .dashboard-white.item-fte a {
    align-items: flex-start;
  }
}

.sidebar.close {
  .item-fte {
    a {
      height: 36px;
      margin: 0 0 5px;
    }
  }

  .nav-links li .dashboard-white.item-fte a:hover {
    background: #C5AC2E;
  }

  .nav-links li .dashboard-white.item-fte a {
    align-items: center;
  }
}

.sidebar.close .nav-links li .dashboard-white a span.img-span img:hover {
  background-color: $bg_gold;
}

.sidebar .nav-links li .dashboard-white a i {
  font-size: 18px;
  color: $text_white;
  align-items: center;
  line-height: 26px;
  display: block;
  transition: all 0.4s ease;
  cursor: pointer;
  transform: rotate(-180deg);
  padding-left: 11px;
}

.sidebar .nav-links li .dashboard-white a span.link_name.show i {
  transform: rotate(0deg);
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu {
  padding: 0px 4px 14px 42px;
  margin-top: 0px;
  display: block;
}

.sidebar .nav-links li .sub-menu.showmenu {
  display: block;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  background-color: transparent;
  color: $text_white;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  padding: 6px 0;
  width: 153px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  font-family: $font_primary;
}

.sidebar .nav-links li .sub-menu li:hover {
  background-color: $bg_gold;
}

.sidebar .nav-links li .sub-menu a.link_name {
  display: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
  text-decoration: none;
}

.sidebar .nav-links li .sub-menu a i {
  font-size: 18px;
  color: $text_white;
  line-height: 26px;
}

.sidebar .nav-links li .dashboard-white a i.white-right-caret {
  display: none;
}

.sub-img-span {
  display: flex;
  align-items: center;
  justify-content: left;
}

/*collapsed-links
 */
.sidebar.close {
  width: 48px;
  overflow: visible;
  position: fixed;
  transition: all 0.4s ease-in-out;
}

.sidebar.close .nav-links {
  opacity: 1;
  transition: all 0.4s ease;

}

.close {
  float: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $text_black;
  text-shadow: none;
  opacity: 1;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}

.sidebar.close .nav-links li .dashboard-white {
  position: relative;
  width: 48px;
}


.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 0px 0px;
  transition: all 0.4s ease;
  opacity: 0;
  display: block;
  width: 170px;
  height: auto;
  background-color: $bg_white;
  pointer-events: none;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1098039216);
  border-right: 1px solid rgba(112, 112, 112, 0.1098039216);
}

.sidebar.close .nav-links li .sub-menu.showmenu {
  display: block !important;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: all;

}

.sidebar.close .nav-links li .sub-menu .link_name {
  width: 170px;
  background-color: $blue_bg;
  padding: 8px 10px;
  transition: all 0.4s ease;
  color: $text_white;
  display: none;
}

.sidebar.close .nav-links li .sub-menu a {
  color: $text_light_gray;
  width: 170px;
  padding: 8px 5px 5px 8px;
  font-weight: 500;
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu a:hover {
  color: $text_white;
}

.sidebar.close .nav-links li .dashboard-white a span.link_name {
  opacity: 0;
  background-color: #555555;
  position: absolute;
  left: 48px;
  min-width: 230px;
  padding: 8px 10px;
  top: -10px;
  transition: all 0.0s ease-in-out;
  pointer-events: none;
}

.sidebar.close .nav-links li .dashboard-white.active a span.link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar.close .nav-links li .dashboard-white:hover a span.link_name {
  opacity: 1;
  color: $text_white;
  top: 0;
  transition: all 0.7s ease;
  pointer-events: all;

}

.sidebar.close .nav-links li .sub-menu a.link_name {
  display: block;
}

.sidebar.close .nav-links li .sub-menu a i {
  color: $text_light_gray;
  font-size: 16px;
  line-height: 22px;
}

.sidebar.close .nav-links li .dashboard-white a i {
  display: none;
}

.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

// main
.sidebar~.main-div {
  width: calc(100% - 250px);
  margin: 0px 10px 0px 0px;
  position: fixed;
  left: 250px;
  transition: all 0.5s ease-in-out;
  background-color: $bg_white;
  height: calc(100vh - 48px);
  top: 48px;
  z-index: 0;
}

.sidebar.close~.main-div {
  width: calc(100% - 48px);
  left: 48px;
  transition: all 0.5s ease-in-out;
}

.inner-div-dashbord {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}


a {
  text-decoration: none !important;
}

.sub-menubread {
  width: auto;
  z-index: 0;
  transition: all 0.5s ease-in-out;
}

.sub-menubread .sub-menubread-child {
  display: flex;
  padding-left: 0rem;
  margin-bottom: 0rem;
  align-items: center;
  justify-content: left;

}

.sub-menubread .sub-menubread-child li {
  list-style: none;
  position: relative;
  width: auto !important;
  margin: 0 0px;
  transition: all 0.4s ease-in-out;
  display: inline;
}

.sub-menubread .sub-menubread-child li a {
  display: flex;
  align-items: center;
  justify-content: left !important;
  width: 100%;
  padding: 6px 6px;
  font-size: 13px;
  font-family: $font_primary;
  font-weight: 500;
  color: $text_light_gray;
}

.sub-menubread .sub-menubread-child li a:hover {
  background-color: transparent;
}

.angle-bread {
  margin-right: 10px;
}

// white-header
.sidebar~.main-div .white-header {
  position: fixed;
  background: $bg_white;
  border-bottom: 1px solid $border_gray2;
  width: 100%;
  left: 0px;
  top: 45px;
  padding-left: 220px;
  transition: all 0.4s ease-in-out;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.sidebar.close~.main-div .white-header {
  padding-left: 50px;
  transition: all 0.4s ease-in-out;
}

.button-list {
  display: flex;
  width: auto;
  padding: 0 35px 0 0;
  align-items: center;

  ul {
    display: flex;
    width: 100%;
    padding-left: 0rem;
    margin-bottom: 0px;
    align-items: center;
    justify-content: end;

    li {
      list-style: none;
      position: relative;
      width: auto;
    }
  }
}

.button-list ul li.excel-btn {
  width: 28px;
  height: 28px;
}

.download-as button {
  padding: 0 15px !important;
}

.button-list ul li.pdf-btn {
  width: 28px;
  height: 28px;
  position: relative;
}

.button-list ul li.pdf-btn:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 28px;
  background-color: $bg_light_white;
  right: -5px;
  top: 0;
}

.button-list {
  li {
    height: 26px;

    .mat-stroked-button {
      border: 1px solid #707070;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $font_primary;
      border-radius: 5px;
      padding: 0 10px;

      .mat-button-wrapper {
        font-size: 12px;
        font-weight: 500;
        color: #555555;

        .icons-image {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .save-btn {
    margin: 0 15px;
  }

  // .download-btn {
  //   .mat-stroked-button {
  //     border: 0;
  //   }
  // }
}


// login-page
.login-main {
  width: 100%;
  height: 100vh;
}

.blue-bg-div {
  width: 65%;
  height: 100vh;
  background: url('assets/img/craousel_image.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 60px;

  .banner-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;

    .banner-count {
      font: normal normal 600 128px/150px 'Open Sans';
      letter-spacing: 0px;
      color: #C5AC2E;
      line-height: 1;
      margin-right: 20px;
      font-weight: 700;
    }

    .banner-title-text {
      display: flex;
      flex-direction: column;
      font: normal normal 300 20px/36px 'Open Sans';
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #fff;
    }

    span.title-highlight {
      color: #C5AC2E;
      letter-spacing: 0.33px;
      text-transform: uppercase;
      font-weight: 700;
      font: normal normal 800 22px/36px 'Open Sans';
      line-height: 1;
    }

    span.title-services {
      font: normal normal normal 26px/36px 'Open Sans';
      letter-spacing: 0.39px;
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }

  .banner-text {
    color: #fff;
    text-align: left !important;
    font: normal normal 300 16px/25px "Open Sans" !important;
    letter-spacing: 0.07px;
    position: relative;
    display: -webkit-box;
    max-width: 98%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: block;
    }

    &::before {
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: -25px;
      left: 0;
      background: linear-gradient(to right, #C5AC2E, transparent);
    }

    &::after {
      content: '';
      width: 185px;
      height: 4px;
      background-color: #C5AC2E;
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }

  .mat-flat-button {
    background-color: rgb(197 172 46 / 70%);
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 1px 34px;
    margin: 45px 0 0 0;
  }

  .banner-arrow-btn {
    position: absolute;
    bottom: 8px;
    right: 0;
  }
}

.blue-bg-div.dashboard-bg {
  .banner-text {
    position: initial;

    &::before {
      bottom: 35px;
      width: calc(100% - 100px);
      left: 30px;
    }
  }
}

.dashboard-carousel.dashboard-carousel1 .carousel-indicators {
  bottom: 0;
}

.benchmark {
  position: relative;

  .dashboard-carousel {
    .carousel {
      position: inherit;

      .carousel-indicators {
        bottom: -14px;
        left: 0 !important;
        display: inherit;
        margin-left: 15px;
        right: inherit;
      }
    }
  }
}


.slider-bg {
  background-image: url('assets/img/craousel_image_1.svg');
  height: 100vh;
  background-size: cover;
  position: relative;

  &.craousel_image_1 {
    background-image: url('assets/img/craousel_image_1.svg');
  }

  &.craousel_image_2 {
    background-image: url('assets/img/craousel_image_2.svg');
  }

  &.craousel_image_3 {
    background-image: url('assets/img/craousel_image_3.svg');
  }

  &.craousel_image_4 {
    background-image: url('assets/img/craousel_image_4.svg');
  }

  .col-left {
    display: flex;
    align-items: center;

    .carousel-left {
      padding: 110px 0;

      .logo {
        padding: 0px 0 100px;
      }

      h2 {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
      }

      p {
        font-size: 26px;
        color: #fff;
        font-weight: 300;
        min-height: 180px;
        position: relative;

        &::before {
          content: '';
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to right, #C5AC2E, transparent);
        }
      }
    }
  }

  .col-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .copyright {
    position: absolute;
    right: 50px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    bottom: 10px;
  }

  .login-btn {
    font-size: 20px;
    font-weight: 500;
    padding: 6px 124px;
    margin: 50px 0 0;

    &:hover {
      color: #fff;
    }
  }
}

.carousel-indicators {

  // display: inline;
  // bottom: 30%;
  // margin-left: 14.2%;
  button {
    height: 12px !important;
    width: 12px !important;
    border-radius: 50%;
    background-color: #C5AC2E !important;
  }
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
}

.dashboard-slider-bg {
  background-image: url('assets/img/craousel_image_1.svg');
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.craousel2_image_1 {
    background-image: url('assets/img/craousel_image_1.svg');
  }

  &.craousel2_image_2 {
    background-image: url('assets/img/craousel_image_2.svg');
  }

  &.craousel2_image_3 {
    background-image: url('assets/img/craousel_image_3.svg');
  }

  &.craousel2_image_4 {
    background-image: url('assets/img/craousel_image_4.svg');
  }
}

.dashboard-carousel {
  .carousel-indicators {
    bottom: -35px;
  }
}

// form-div
.form-div {
  width: 35%;
  height: 100vh;
  padding: 0px 60px;
  position: relative;

  .loginpage-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;

    img {
      width: 131px;
      height: 70px;
    }
  }

  // login-form
  .login-form {
    width: 100%;

    h2 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 400;
      font-family: $font_primary;
      color: $text_black;
      opacity: 0.7;
    }

    form {
      width: 100%;
      padding-top: 10px;

      .btn-login {
        width: 100%;
        height: 36.53px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0px;
        background-color: $blue_bg;
        border-radius: 4px;
        text-align: center;
        color: $text_white;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        font-family: $font_primary;

        &:focus {
          box-shadow: none;
        }
      }

      .form-check-input {
        &:focus {
          box-shadow: none;
        }
      }

      .mat-checkbox-layout {
        font-size: 13px;
        font-weight: 500;
        color: #555555;
      }

      .login-btn {
        margin-top: 50px;
      }
    }
  }

  // footer
  footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: $text_black;
      opacity: 0.8;
      font-family: $font_primary;
      position: fixed;
      bottom: 0;
    }
  }
}


label.forgot-label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 13px;
    line-height: 18px;
    font-weight: 200;
    color: $text_gray;
    font-family: $font_primary;
  }
}

.login-form {
  form.example-form {
    width: 100%;

    .mat-form-field {
      display: block;
      position: relative;
      text-align: left;
      width: 100%;

      .mat-form-field-wrapper {
        padding-bottom: 0em;

        .mat-input-element {
          outline: none;
          padding: 0px 9px;
          margin: 0;
          width: 336px;
          max-width: 100%;
          vertical-align: bottom;
          text-align: inherit;
          box-sizing: content-box;
          border: 1px solid #555555;
          display: flex;
          align-items: center;
          height: 36px;
          border-radius: 4px;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          font-family: $font_primary;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          color: $text_gray;
          background-color: $bg_white;
          ;
        }
      }
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 4px 4px 0 0;
      padding: 0em 0em 0 0em;
      background-color: $bg_white;
    }

    .mat-form-field-appearance-fill .mat-form-field-infix {
      padding: 0em 0 0em 0;
      border-top: 0em solid transparent;
    }

    appearance-fill .mat-form-field-flex {
      background-color: $bg_white;
    }

    .mat-form-field-appearance-fill .mat-form-field-ripple {
      bottom: 0;
      height: 0px;
    }

    .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: transparent;
      height: 0px;
    }

    .label {
      font-size: 12px;
      line-height: 30px;
      font-weight: 500;
      font-family: $font_primary;
      color: #2b2b2b;
      opacity: 0.8;
    }

    .mat-checkbox .mat-checkbox-ripple {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      height: 0px;
      width: 0px;
      z-index: 1;
      pointer-events: none;
    }

    .mat-checkbox-frame {
      border: 1px solid $border_gray;
      border-radius: 4px;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $blue_bg;
      border-radius: 4px;
      border: 1px solid $border_blue;
    }

    .mat-flat-button {
      background-color: #C5AC2E !important;
      border-radius: 4px;
      text-align: center;
      color: $text_white !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 18px !important;
      box-sizing: border-box;
      position: relative;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      display: inline-block;
      white-space: nowrap;
      text-decoration: none;
      vertical-align: baseline;
      text-align: center;
      margin: 0;
      min-width: 100% !important;
      line-height: 36px;
      padding: 0 16px;
      border-radius: 4px;
      overflow: visible;
      height: 42px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font_primary;
    }

    .form-email {
      margin: 15px 0px 15px 0px;
      position: relative;

      .forgetpass {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #2b2b2b;
        font-family: $font_primary;
        position: absolute;
        top: 6px;
        right: 0;
        text-decoration: underline !important;
      }
    }

    .res-btn {
      margin-top: 25px 0px 0px 0px;
    }
  }
}

.mat-checkbox.deselect-checkbox {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -15px;
    background: rgba(0, 0, 0, 0.04);
    width: 200px;
    height: 24px;
  }
}


.deselect-checkbox .mat-checkbox-label {
  font-size: 12px;
  color: #555;
}

.mat-checkbox-checked.deselect-checkbox .mat-checkbox-label {
  color: #C5AC2E;
}

.mat-checkbox-checked.deselect-checkbox .mat-checkbox-inner-container .mat-checkbox-background {
  background-color: #C5AC2E !important;
}

.deselect-checkbox .mat-checkbox-checkmark {
  top: 0.4px;
}

.mat-option.removed-bg-color.mat-active {
  background: transparent;
}

.mat-select-panel.mat-primary {
  width: 150px !important;
  min-width: calc(100% + 20px) !important;
  margin-left: 30px !important;
  margin-top: 40px !important;
  border-radius: 0px 4px 0px 4px !important;
  overflow-x: hidden !important;
}

.mat-form-field.remove-transform {
  .mat-select-arrow {
    margin-top: 4px !important;
  }
}

.remove-transform.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #000B1C !important;
  font-size: 13px;
  font-weight: 400 !important;
  background-color: #fff;
  width: calc(100% - 20px) !important;
}

.remove-transform.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: none !important;
}

.mat-menu-panel .mat-menu-item:hover {

  .remove-transform.mat-form-field-appearance-legacy .mat-form-field-label {
    background: #C5AC2E;
    color: #fff !important;
  }

  .mat-select-arrow {
    color: #fff;
  }
}

.close {
  float: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 1;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}


.sidebar.close .nav-links li.Hex-pink a span.img-span {
  opacity: 1;
  position: relative;
}

.sidebar.close .nav-links li.Hex-pink a span.link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar.close .nav-links li.Hex-pink {
  display: block;
}

.sidebar.close .nav-links li.Hex-pink .carret-icon {
  position: absolute;
  top: 15px;
  right: 3px;
}

.sidebar.close .nav-links li.Hex-pink .dd-menu {
  left: 56px;
  top: -10px;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li.Hex-pink:hover .dd-menu {
  top: 52px;
  transition: all 0.5s ease;
}

.sidebar.close .nav-links li.Hex-pink .dd-menu .out-icon {
  left: -8px;
  font-size: 18px;
}

.sidebar.close .nav-links li .colla-menue {
  display: block;
  position: fixed;
  left: 48px;
  bottom: 18px;
  display: none;
}

.sidebar.close .nav-links li .colla-menue ul {
  width: 172px;
  border: 0px solid #fff;
  background-color: #fff;
}

.sidebar.close .nav-links li .colla-menue ul li.blue-bg {
  background-color: #014991;
}

.sidebar.close .nav-links li .colla-menue ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: 20px;
  color: #707070;
  font-weight: 500;
}

.sidebar.close .nav-links li .colla-menue ul li {
  padding: 5px 5px 0px 2px;
}

.sidebar.close .nav-links li .colla-menue ul li:first-child {
  padding: 6px 5px 6px 0px;
}


.sidebar.close .nav-links li .colla-menue ul li a img {
  filter: brightness(0) invert(0);
}

.sidebar.close .nav-links li .colla-menue ul li a i {
  font-size: 18px;
  color: #707070;
  line-height: 26px;
}

.sidebar.close .nav-links li .sub-menu .Client-menu {
  position: fixed;
  left: 224px;
  bottom: 18px;
  width: 170px;
  z-index: 2;
  border: 1px solid #014991;
  border-radius: 0px;
  display: none;
  transition: all 0.9s ease;
}

.sidebar.close .nav-links li .sub-menu li .Client-menu ul li a {
  transition: all 0.9s ease;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #707070;
  padding: 5px 10px 0px 10px;
}

.sidebar.close .nav-links li .sub-menu .Client-menu .out-icon {
  position: absolute;
  top: 4px;
  left: -6px;
  font-size: 16px;
}

.sidebar.close .nav-links li .sub-menu li .Client-menu ul li a:hover {
  color: #014991;
}

.sidebar.close .nav-links li .dashboard-white:hover a span.link_name.hide-colla {
  display: none;
}

.sidebar.close .nav-links li .sub-menu a i {
  color: #707070;
  font-size: 16px;
  line-height: 22px;
}

.sidebar.close .nav-links li .sub-menu a span.sub-img-span img {
  filter: brightness(0) invert(0);
}


////////////////////////////////////////////////side baar//////////////////////////

.sidebar .nav-links li.Hex-pink {
  width: 100%;
  height: 42px;
  background-color: #FFF1D3;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.sidebar .nav-links li.Hex-pink a.hex-btn {
  white-space: nowrap;
  pointer-events: all;
  width: 220px;
}

.sidebar .nav-links li.Hex-pink a span.img-span {
  width: 42px;
  height: 42px;
  opacity: 1;
}

.sidebar .nav-links li.Hex-pink a span.link_name {
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  color: #000000;
  opacity: 1;
  pointer-events: auto;
  font-family: $font_primary;
}

.sidebar .nav-links li.Hex-pink a:hover {
  text-decoration: none;
}

.sidebar .nav-links li.Hex-pink .carret-icon {
  align-items: center;
  padding-left: 85px;
}

.sidebar .nav-links li.Hex-pink .dd-menu {
  position: fixed;
  left: 226px;
  top: 52px;
  width: 196px;
  z-index: 2;
  border: 1.5px solid #014991;
  border-radius: 4px;
  display: none;
  transition: all 0.9s ease;
  background-color: #fff;

}

.sidebar .nav-links li.Hex-pink .dd-menu ul {
  padding-left: 0rem;
}

.sidebar .nav-links li.Hex-pink:hover .dd-menu {
  display: block;
  transition: all 0.9s ease;

}

.sidebar .nav-links li.Hex-pink .dd-menu .out-icon {
  position: absolute;
  left: -7px;
  top: 10px;
  color: #014991;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
  background-color: #fff;
  font-family: $font_primary;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a .wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a .wrap span.greenicon img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  visibility: hidden;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a .wrap:hover {
  background-color: #FFF1D3;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a.active .wrap {
  background-color: #FFF1D3;
}

.sidebar .nav-links li.Hex-pink .dd-menu ul li a.active .wrap span.greenicon img {
  visibility: visible;
}


.sidebar .nav-links li p {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #A6B0CF;
  margin: 6px 0px 0px 12px;
  display: block;
}

.sidebar.close .nav-links li.Hex-pink a.hex-btn {
  white-space: nowrap;
  pointer-events: none;
}

.sidebar.close .nav-links li p {
  display: none;
}

.sidebar .nav-links li .sub-menu .Client-menu {
  position: fixed;
  left: 224px;
  bottom: 15px;
  width: 170px;
  z-index: 2;
  border: 1px solid #014991;
  border-radius: 0px;
  display: none;
  transition: all 0.9s ease;
}

.sidebar .nav-links li .sub-menu li .Client-menu ul li a {
  transition: all 0.9s ease;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #707070;
  padding: 5px 10px;
  font-family: $font_primary;
}

.sidebar .nav-links li .sub-menu li .Client-menu ul li a:hover {
  color: #014991;
}

.sidebar .nav-links li .sub-menu .Client-menu .out-icon {
  position: absolute;
  top: 7px;
  left: -6px;
}

.sidebar .nav-links .colla-menue {
  display: none;
}

.sidebar .nav-links li .colla-menue {
  display: none;
}

.sidebar .nav-links li .dashboard-white a i.white-right-caret {
  display: none;
}

.cbtn-primary {
  font-size: 12px;
  color: #000000;
  background-color: #E8E8E8;
  border-radius: 11px;
  padding: 2px 20px;
  display: inline-block;
  margin: 0 15px 0 0;
  border: 0;
  font-family: 'Open Sans', 'Inter', 'Roboto';
}

.cbtn-secondary {
  background-color: #C5AC2E !important;
  color: #fff;
}

.cbtn-border {
  border-radius: 4px;
  border: 1px solid #F5F5F5;
  background-color: transparent;
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
  color: #555555;
  font-weight: 500;
}

.wrap {
  display: flex;
  padding: 30px 10px 20px;
}

.pyramid-group text {
  font-size: 9px;
  font-weight: 500;
  color: #333333;
}

.section {
  background-color: #fff;
  border-radius: 4px;
}

.headings {
  font-size: 13px;
  font-weight: 600;
  color: #2B2B2B;
}

.ngx-charts-outer {
  width: 100% !important;
}

.custom-tab .ngx-charts {
  width: 40%;
  height: 100%;
}

.ngx-charts-outer .chart-legend {
  width: 40% !important;
}

.chart-legend div {
  width: 100% !important;
}

.chart-legend .legend-title {
  display: none;
}

.chart-legend .legend-labels {
  background: transparent !important;
  max-height: 100% !important;
}

.chart-legend .legend-label-color {
  height: 12px !important;
  width: 12px !important;
}

.chart-legend .legend-label-text {
  line-height: 11px !important;
  font-size: 10px !important;
  color: #000000;
}

.chart-legend .legend-label {
  margin: 7px 0 !important;
}

.bg-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F3F3F3;
  font-size: 11px;
  color: #333333;
  padding: 4px 7px;
}

.select-options {
  height: calc(100vh - 394px) !important;
  padding: 10px;
  margin: 0;
  list-style: none;
  overflow: auto;

  .mat-checkbox-inner-container {
    height: 13px;
    width: 13px;
  }

  li {
    font-size: 13px;
    font-weight: 500;
    color: #333333;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: flex;
      align-items: center;

      input {
        margin: 0 5px 0 0;
      }
    }
  }
}

$continentaleurope: #000000;
$latinamerica: #000000;
$northamerica: #000000;
$asia: #000000;
$easterneurope: #000000;
$mena: #000000;
$anz: #000000;



#continentaleurope {
  fill: $continentaleurope
}

#latinamerica {
  fill: $latinamerica
}

#northamerica {
  fill: $northamerica
}

#asia {
  fill: $asia
}

#easterneurope {
  fill: $easterneurope;
}

#mena {
  fill: $mena;
}

#anz {
  fill: $anz;
}

.legend-continentaleurope {
  background: $continentaleurope;
}

.legend-latinamerica {
  background: $latinamerica;
}

.legend-northamerica {
  background: $northamerica
}

.legend-asia {
  background: $asia
}

.legend-easterneurope {
  background: $easterneurope;
}

.legend-mena {
  background: $mena;
}

.legend-anz {
  background: $anz;
}

.selected-region {
  fill: #C5AB2E !important;
}

.selected-region:hover {
  stroke: #000;
  stroke-width: 1px;
}

.regions {
  position: relative;
}

// .regions::before {
//   content: 'Select Location(s) from the highlighted region on the map';
//   position: absolute;
//   width: 100%;
//   top: 0%;
//   display: none;
// }

.regions:hover {
  stroke: #000;
  stroke-width: 1px;
  cursor: pointer;

  &::before {
    display: block;
  }
}

.customAlignIcon {
  height: 19px !important;
  cursor: pointer;
}

.not-selected {
  stroke: none;
}

.pyramid-group path {
  stroke: #fff;
  stroke-width: 2px;
}

#pyramid {
  overflow: visible;
}

.title {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}

.blended-wrap {
  height: calc(100vh - 120px);
  overflow: auto;
  padding: 0 5px;
}

.donut-section {
  padding: 10px 15px;
  position: relative;
}

.switch {
  position: relative;
  width: 78px;
  height: 24px;

  .mat-button-toggle {
    position: relative;
    top: 0;
    z-index: 2;
    cursor: pointer;
    height: 18px;
    width: 36px;
    background: transparent;
  }

  .mat-button-toggle-checked {
    background: transparent;
  }

  .toggle-outside {
    height: 100%;
    transition: 0.25s ease all;
    background: #fff;
    position: absolute;
    width: 78px;
    left: 0;
    z-index: 1;
  }

  .toggle-inside {
    border-radius: 2px;
    background: #C5AC2E;
    position: absolute;
    transition: 0.25s ease all;
    height: 18px;
    width: 36px;
  }

  .mat-button-toggle-group {
    border-radius: 4px;
    padding: 2px;
    border: 1px solid #E8E8E8;
    box-shadow: none;
    height: 24px;
  }

  .mat-button-toggle-button {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-button-toggle-label-content {
    font-size: 13px;
    width: 36px;
    height: 100%;
    text-align: center;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    color: #555555;
    transition: opacity 0.25s ease;
    cursor: pointer;
    line-height: 19px !important;
    font-weight: 500;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #fff;
    padding: 0 8px !important;
  }

  .mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 2px;
  }

  .mat-button-toggle~.mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 40px;
  }

  .mat-button-toggle~.mat-button-toggle~.mat-button-toggle-label-content {
    left: 0;
  }

  .mat-button-toggle~.mat-button-toggle-label-content~.mat-button-toggle-label-content {
    right: 0;
    left: unset;
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left: 0 !important;
  }

  .mat-button-toggle:hover .mat-button-toggle-focus-overlay {
    background-color: transparent;
  }

  .mat-button-toggle-disabled {
    opacity: 0.4;
  }
}

.tower-location-switch.switch {
  width: 118px;

  .mat-button-toggle {
    width: 59px;
  }

  .toggle-outside {
    width: 118px;
  }

  .toggle-inside {
    width: 59px;
  }

  .mat-button-toggle~.mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 60px;
  }

  .mat-button-toggle-label-content {
    width: 59px;
  }
}

.percentile-switch.switch {
  width: 200px;

  .mat-button-toggle {
    width: 68px;
  }

  .toggle-outside {
    width: 200px;
  }

  .toggle-inside {
    width: 68px;
  }

  .mat-button-toggle~.mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 70px;
  }

  .mat-button-toggle~.mat-button-toggle~.mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 138px;
  }

  .mat-button-toggle-label-content {
    width: 67px;
  }
}

.custom-select {
  margin: 0 0 10px 0;

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 1px 5px 2px 10px;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    height: 24px;
    min-width: 280px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-select-min-line {
    font-size: 12px;
    font-weight: 500;
    color: #C5AC2E;
  }

  .mat-select-arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #79898F;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 18px;
    font-size: 12px;
    left: 10px;
    color: #000 !important;
  }

  .mat-select-empty .mat-select-arrow-wrapper {
    margin: -3px 0 0 0;
  }

  // &.download-select {
  //   margin: 0;
  //   .mat-form-field-appearance-legacy .mat-form-field-infix {
  //     border: 0;
  //     min-width: 50px;
  //     width: 110px;
  //     font-family: "", sans-serif;
  //     font-weight: 500;
  //     color: #555;
  //     .mat-select-value {
  //       line-height: 16px;
  //     }
  //   }
  // }
}

.custom-select.mw-150 {
  margin: 0 0 5px 0;

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    min-width: 150px;
  }
}

.custom-select-filter {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 2px 5px 2px 10px;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    height: 24px;
    min-width: 80px;
    width: 100px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-select-min-line {
    font-size: 12px;
    font-weight: 500;
    color: $hex_gold;
  }

  .mat-select-arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #79898F;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 18px;
    font-size: 12px;
    left: 10px;
    color: #000 !important;
  }

  .mat-select-empty .mat-select-arrow-wrapper {
    margin: -3px 0 0 0;
  }

  // &.download-select {
  //   margin: 0;
  //   .mat-form-field-appearance-legacy .mat-form-field-infix {
  //     border: 0;
  //     min-width: 50px;
  //     width: 110px;
  //     font-family: "", sans-serif;
  //     font-weight: 500;
  //     color: #555;
  //     .mat-select-value {
  //       line-height: 16px;
  //     }
  //   }
  // }
}

.custom-toggle-btn {
  .mat-button-toggle {
    background-color: #F5F5F5;

    &.mat-button-toggle-checked {
      background-color: #C5AC2E;

      .mat-button-toggle-label-content {
        color: #fff
      }
    }
  }

  .mat-button-toggle-group {
    height: 24px;
    line-height: 20px;
  }

  .mat-button-toggle-label-content {
    height: 24px;
    line-height: 26px;
    font-size: 12px;
    color: #555555;
    font-weight: 500;
  }
}

.heading-wrap {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
  }
}

.category-wrap {
  .custom-tree {
    height: calc((100vh - 335px)/(20/11));
    border: 0;
    background-color: #fff;

    .mat-tree-node[aria-level="1"] {
      background-color: transparent;
      border-bottom: 1px solid #E8E8E8;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .chart-wrap,
  .ts-list-wrap {
    width: 50%;
  }

  // .mat-checkbox-layout .mat-checkbox-label {
  //   font-size: 11px !important;
  //   font-weight: 400 !important;
  // }
  .donut-content {
    height: calc((100vh - 310px)/(20/11));
    overflow: auto;
  }
}

.region-main {
  .selection {
    height: 100%;
    border-radius: 0 6px 6px 0px;

    .mat-chip-list-wrapper {
      max-height: calc((100vh - 335px)/(20/9));
      min-height: 134px;
    }

    .bg-heading {
      display: none;
    }
  }

  .ruBenchmarkPage {
    .selection {
      .mat-chip-list-wrapper {
        max-height: calc((100vh - 335px) / 2.2222222222);
        min-height: 45px;
        height: 45px;
        padding-top: inherit;
        display: flex !important;
        align-items: center;
      }
    }

    .location-selection {
      display: none;
    }
  }

  .country-list {
    display: none;
  }

  .map-wrap {
    width: 100%;
  }

  .select-countries-wrap {
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 9;
  }

  .selected-loc-title span .icon-info-new,
  .offshore-title {
    display: none;
  }

  #map {
    width: 70% !important;
    height: 50px !important;
  }
}


.custom-tree {
  border-left: 1px solid #F2F2F2;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;

  .mat-tree {
    height: 100%;
  }

  .mat-tree-node {
    min-height: 32px;
    position: relative;

    &[aria-expanded="true"] {
      background-color: #F5F5F5;
    }

    &[aria-level="2"] .mat-checkbox-layout .mat-checkbox-label {
      font-weight: 400;
      color: #555555;
    }
  }

  .mat-checkbox-layout {
    width: 100%;
  }

  .mat-icon-button {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .mat-checkbox {
    width: calc(100% - 25px);
    left: 25px;
    position: absolute;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    font-size: 13px;
    color: #2B2B2B;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
  }

  .mat-checkbox-inner-container {
    height: 13px;
    width: 13px;
  }

  .mat-tree-node[aria-level="2"] {
    .mat-checkbox {
      width: calc(100% - 45px);
      left: 45px;
    }
  }

  .mat-icon-button .mat-icon {
    font-size: 16px;
  }

  .mat-checkbox-disabled {
    opacity: 0.4;
  }
}


.mat-checkbox {
  .mat-checkbox-inner-container {
    height: 13px;
    width: 13px;
  }
}

.mat-pseudo-checkbox {
  height: 13px !important;
  width: 13px !important;
  border: 1px solid !important;
  color: #555555 !important;

  &.mat-pseudo-checkbox-checked {
    color: transparent !important;
  }
}

.mat-pseudo-checkbox::after {
  border-bottom: 1px solid currentColor !important;
}

.mat-pseudo-checkbox-checked::after {
  border-left: 1px solid currentColor !important;
}



.tags {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: #2F68B1;
  color: #fff;
  border-radius: 11px;
  margin: 0 10px 5px 0;
}

.tags i {
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-position: center;
  background-size: 6px;
  margin: 0 5px 0 0;
}

.selection {
  background-color: rgba(255, 255, 255, 0.9);
  border-left: 1px solid #F3F3F3;

  .bg-heading {
    height: 32px !important;
    padding: 4px 10px;
    border-radius: 0;
    // .title {
    //   font-size: 13px;
    //   color: #555555;
    // }
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: #f3f3f3;
  }

  .tags {
    font-size: 12px;
    padding: 2px 20px !important;
    background-color: #C5AC2E !important;
    color: #fff !important;
    min-height: 22px !important;
    text-transform: capitalize;
    font-weight: 400;
    margin: 2px 0px 2px 5px !important;
    line-height: 8px;
    border-radius: 11px;
    font-family: 'Open Sans', 'Inter', 'Roboto';

    .offshore-flag {
      padding-left: 10px;

      img {
        position: absolute;
        top: 4px;
      }
    }
  }

  .mat-expansion-panel {
    border-radius: 0;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .mat-expansion-panel-body {
    padding: 0 10px 0 0;
  }

  .mat-form-field-infix {
    width: auto !important;
    padding: 0 !important;
    border: 0;
  }

  .mat-chip-list-wrapper {
    margin: 0;
    height: 130px;
    // overflow-y: hidden;
    padding: 10px;
    display: block !important;
    overflow-x: hidden;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: #fff;
    opacity: 0.9;
    margin-left: 0;
    margin-right: 5px;
  }

  .tags-list {
    display: block !important;
  }
}

.category-main {
  .bg-heading {
    height: 32px;
    padding: 4px 10px;
    border-radius: 0;

    .title {
      color: #555555;
    }
  }
}

.offshore-flag {
  padding-left: 10px;

  img {
    width: 13px;
  }
}

.custom-tab {
  .mat-tab-body-content .bg-heading-big {
    font-size: 18px;
    justify-content: center;
    padding: 12px;
    font-weight: 500;
  }

  .mat-tab-body-content {
    border-bottom: 1px solid #F3F3F3;
    border-left: 1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;
    border-radius: 5px;
  }

  .mat-tab-group {
    margin: 20px;
  }

  .card-wrap {
    .card-wrap-div {
      img {
        bottom: 75px;
      }
    }
  }

  .card-wrap {
    img {
      bottom: 115px;
    }
  }

  .mat-card {
    padding: 0;
    box-shadow: 0 2px 4px rgb(0 0 0 / 9%);
    border-radius: 5px;
    text-align: center;
    min-width: 345px;
    min-height: 325px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #C8C8C8;

    &.category-card {
      .mat-card-title {
        border-bottom: 1px solid #F2F2F2;
        //margin-top: 10px; 
        background-color: #fbfbfb;
        position: absolute;
        bottom: 0;
      }
    }

    &.card-selected {
      border-color: #C5AC2E;

      .mat-card-title {
        background: #c5ac2e;
        color: #fff;
        border-bottom: none;
        position: absolute;
        bottom: 0;
      }

      .title-full-form {
        color: #fff;
      }
    }
  }

  .mat-card-title {
    font-size: 22px;
    font-weight: 700;
    color: #1F477B;
    padding: 15px;
    background-color: #FBFBFB;
    width: 100%;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
    margin-top: 30px !important;
  }

  img {
    width: 75px;
    position: absolute;
  }

  .title-full-form {
    font-size: 13px;
    color: #555555;
    font-weight: 400;
  }

  .card-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .card-wrap-div {
      flex-basis: 30%;
    }

    // .mat-card{
    //   max-width: 375px;      
    // }
  }

  .card-wrap-2 {
    height: calc(100vh - 200px);
    overflow: auto;
  }

  .mat-tab-label {
    padding: 0px !important;
    flex: 0 0 20%;
    width: 20%;
    opacity: 1;
    height: 52px !important;
    z-index: 1;
    overflow: visible;

    &:nth-child(1) {
      z-index: 5;
    }

    &:nth-child(2) {
      z-index: 4;
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 2;
    }

    &:nth-child(5) {
      z-index: 1;
      overflow: hidden;
    }

    &:nth-child(even) {
      .mat-tab-label-content {
        .mat-title-wrap {
          background-color: #E8E8E8;
          position: relative;

          &::after {
            content: '';
            background: url('assets/img/triangle-dark.svg');
            height: 52px;
            width: 21px;
            position: absolute;
            right: -21px;
            top: 0;
          }
        }
      }

      &.mat-tab-label-active {
        .mat-tab-label-content {
          .mat-title-wrap {
            &::after {
              background: url('assets/img/triangle-dark-active.svg');
              background-repeat: no-repeat;
              width: 24px;
              position: absolute;
              right: -22px;
              top: -1px;
            }
          }
        }
      }
    }

    &:nth-child(odd) {
      .mat-tab-label-content {
        .mat-title-wrap {
          background-color: #F8F8F8;
          position: relative;

          &::after {
            content: '';
            background: url('assets/img/triangle-light.svg');
            height: 52px;
            width: 21px;
            position: absolute;
            top: 0;
            right: -21px;
          }
        }
      }

      &.mat-tab-label-active {
        .mat-tab-label-content {
          .mat-title-wrap {
            &::after {
              background: url('assets/img/triangle-light-active.svg');
              background-repeat: no-repeat;
              width: 24px;
              position: absolute;
              right: -22px;
              top: -1px;
            }
          }
        }
      }
    }

    &.mat-tab-label-active {
      border: 1px solid #C5AC2E;
    }

    // &.mat-tab-disabled {
    //   opacity: 0.5;
    //   .mat-tab-label-content {
    //     .mat-title-wrap {
    //       &::after {
    //         opacity: 0.5;
    //       }
    //     }
    //   }
    // }
    .mat-tab-label-content {
      color: #737373;
      font-size: 12px;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start !important;
      width: 100%;
      height: 100%;
      white-space: wrap;

      .mat-title-wrap {
        position: relative;
        padding: 0px 0 0 30px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.mat-title-selected {
          background-color: #555555;

          &::after {
            content: '';
            background: url('assets/img/triangle-yellow.svg') !important;
            height: 52px;
            width: 22px !important;
            position: absolute;
            top: 0;
            right: -20px;
            z-index: 1;
          }

          span {
            color: #fff;
            font-size: 12px;
          }

          .selection-value-wrap {
            span {
              font-size: 12px;
            }
          }
        }

        span {
          display: flex;
          align-items: center;

          .icon-info {
            height: 18px;
            width: 18px;
            background-position: -622px -4px;
            margin: 0 0 0 5px;
          }
        }
      }

      .mat-title-wrap:hover .title-hover {
        display: block;
      }

      .title-hover {
        display: none;
        position: absolute;
        top: 55px;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 12px;
        background-color: #e8e8e8;
        left: 50px;

        span {
          color: #555555 !important;
          //display: inline;
        }
      }
    }
  }

  .selection-title {
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    display: flex;
  }

  .area-card {
    min-width: 275px;
    min-height: 175px;
    justify-content: space-around;

    .mat-card-title {
      font-size: 13px;
      font-weight: 500;
      color: #333333;
      background-color: transparent;
    }

    .icons-image {
      width: 50px;
      height: 41px;
    }
  }

  .btns-wrap {
    z-index: 999;
    display: flex;

    .btn-square {
      height: 40px;
      width: 40px;
      border: 0;
      background-color: #555555;
      border-radius: 4px;
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.5;
      }
    }

    .btn-square.btn-prev {
      .icons-image {
        transform: rotate(180deg);
      }
    }
  }

  .mat-ink-bar {
    display: none;
  }

  .selected-value {
    font-weight: 600;
    color: #fff;
    display: inline-block !important;
    margin: 5px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .selection-value-wrap {
    font-weight: 600;
    color: #fff;
    display: inline-block !important;
    margin: 0 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .mat-tab-header {
    overflow: visible;
    background-color: transparent !important;
  }

  .location-selection {
    display: none;
  }

  .regionLocation-wrap {
    max-height: inherit !important;
    min-height: 136px;
  }

}

.region-modal,
.tower-subtower-modal,
.detail-tower-modal,
.variance-spread-modal {
  .mat-dialog-title {
    margin: 0 !important;
  }

  .mat-dialog-container {
    padding: 0 0px;
    height: auto;
  }

  .bg-heading-blue {
    background-color: #555555;
    color: #fff;
    font-size: 15px;
    padding: 7px 15px 7px 20px;
    font-weight: 500;
  }

  .bg-heading-yellow {
    background-color: #C5AC2E;
    font-size: 14px;
    padding: 7px 15px 5px 20px;
    font-weight: 500;
  }

  .mat-button {
    line-height: 28px;
    min-width: auto;
    padding: 0;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
    max-height: 570px;
    min-height: 65vh;
    background: #fff;
  }

  .mat-dialog-actions {
    background-color: #F3F3F3;
    padding: 12px 20px;
    min-height: auto;
  }
}

.tower-subtower-modal {
  .donut-content {
    height: auto !important;
  }
}

.region-modal {
  .select-options {
    height: 300px !important;
  }
}

.offshore-switch {
  width: 144px;

  .toggle-inside {
    width: 70px;
    left: 2px;
  }

  .mat-button-toggle-label-content {
    width: 70px;
  }

  .mat-button-toggle {
    width: 72px;
  }

  .toggle-outside {
    width: 144px;
  }

  .mat-button-toggle~.mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 74px;
  }

  .mat-button-toggle-checked~.toggle-outside .toggle-inside {
    left: 2px;
  }
}



.disable-option {
  opacity: 0.4;
  pointer-events: none;
}

.roles-table {
  //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 5px 5px;

  .ag-body-viewport {
    // height: calc((100vh - 270px)/(20/11));
    overflow: auto;
  }

  .ag-header-row {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    background-color: #737373;
  }

  .ag-header {
    height: 36px;
    min-height: 36px;
    border: 0;
  }

  .ag-header-viewport {
    background-color: #fff;
  }

  .ag-root-wrapper {
    border: 0;
    border-radius: 0 0 5px 5px;
  }

  .ag-row {
    border-bottom: 0;
    font-size: 12px;
    color: #333333;
  }

  .ag-row-odd {
    background-color: #F7F7F7;
  }

  .ag-checkbox-input-wrapper {
    width: 14px;
    height: 14px;
  }

  .ag-checkbox-input-wrapper::after {
    font-size: 15px;
  }

  .ag-cell,
  .ag-header-cell {
    padding: 0 10px;
    border: 0;
    margin: 0 10px;
  }

  .ag-pinned-left-cols-container {
    .ag-cell {
      padding: 0;
      padding-left: 10px;
      margin: 0;
    }

    .ag-header-cell {
      padding: 0;
      margin: 0;
    }
  }

  .ag-pinned-left-header {
    .ag-cell {
      padding: 0;
      padding-left: 10px;
      margin: 0;
    }

    .ag-header-cell {
      padding: 0;
      margin: 0;
    }
  }

  .ag-selection-checkbox {
    margin: 0 6px 0 0;
  }

  .ag-body-viewport::-webkit-scrollbar,
  .ag-center-cols-viewport::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .ag-body-viewport::-webkit-scrollbar-track,
  .ag-center-cols-viewport::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  .ag-body-viewport::-webkit-scrollbar-thumb,
  .ag-center-cols-viewport::-webkit-scrollbar-thumb {
    background: #bbb !important;
    border-radius: 5px !important;
  }

  .ag-body-viewport::-webkit-scrollbar-thumb:hover,
  .ag-center-cols-viewport::-webkit-scrollbar-thumb:hover {
    background: #aaa !important;
  }

  .ag-pinned-left-header {
    border-right: 1px solid #E8E8E8;
  }

  .input-form-control {
    border-bottom: 1px solid #e9dfac !important;
    padding: 0 10px;
    width: 100px !important;
    text-align: right;
    margin: 0 20px;
  }

  .input-form-control input {
    // height: 24px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #E8E8E8;
    background-color: transparent;
    font-size: 12px;
    // width: 74px;
    // margin: 0 0 0 10px;
  }

  .ag-cell-inline-editing {
    padding: 3px 0 0;
    ;
    height: inherit;
    box-shadow: none;
    border: 0;
    border-radius: 0;

    .ag-input-field-input {
      // height: 20px;
      border: 0;
      border-bottom: 1px solid #e9dfac;
      border-radius: 0 !important;
      background: transparent;
      text-align: right;

      // width: 50px;
      // flex: 0 0 50px;
      // margin: 0 0 0 10px;
      &.ag-cell-focus {
        background: transparent !important;
      }

      &:focus {
        box-shadow: none;
        border-color: #C5AC2E;
      }
    }
  }

  .ag-cell-value {
    border: 0;
    background: transparent;

    &:focus {
      border: 0 !important;
    }
  }

  .ag-cell-focus {
    border: 0 !important;
  }

  .oat-total-spend {
    text-align: right;
    padding-right: 5px !important;
  }

  .ag-center-cols-viewport {
    overflow-x: hidden;
    text-align: right;
  }

  .ag-body-horizontal-scroll,
  .ag-body-horizontal-scroll-viewport {
    height: 6px !important;
    max-height: 6px !important;
    min-height: 6px !important;
  }
}

.saved-scenario-table {
  .ag-root-wrapper {
    border: 1px solid #C8C8C8;
    border-radius: 4px;
  }

  .ag-header {
    border-top: 0;
    background-color: #555555;

    .ag-header-row {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .ag-body-viewport {
    height: calc((100vh - 330px) / 2);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #bbb;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }

    .ag-center-cols-container {
      width: 100% !important;
    }

  }

  .ag-row-odd {
    background-color: transparent !important;
  }

  &.recent-download-table {
    .ag-row-odd {
      background-color: #FAFAFA !important;
    }
  }

}

.saved-scenario-section {
  .ag-row {
    &:hover {
      .delete-row {
        opacity: 1;
        color: red;
      }
    }
  }

  .delete-row {
    opacity: 0;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    float: right;
  }
}

.recent-download-table {
  .ag-row {
    border: 0 !important;
  }

  .ag-cell-value[col-id='name'] {
    cursor: pointer;
    // text-decoration: underline;
    font-style: italic;
  }
}

.mat-progress-bar-fill::after {
  background-color: #C5AC2E !important;
}

.form-control:focus {
  box-shadow: none;
}

.role-main {

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: transparent;
  }

  .mat-expansion-panel {
    box-shadow: none;
    position: relative;
    overflow: visible;
    margin: 0px 0 0;
  }

  .mat-expansion-panel-header {
    height: auto;
    font-size: 13px;
    font-weight: 500;
    padding: 3px 0 5px 30px;
    width: fit-content;

    &.mat-expanded {
      .mat-expansion-indicator {
        top: 6px;
      }
    }

    .mat-content {
      flex: 0 0 auto;
    }

    .mat-expansion-indicator {
      position: absolute;
      left: 12px;
      top: 1px;
    }

    .mat-expansion-indicator::after {
      border-width: 0 1.5px 1.5px 0;
      padding: 2px;
    }
  }
}

.left-col-inner {
  height: calc(100vh - 115px);
}

.right-col-inner {
  height: calc(100vh - 115px);
  overflow: auto;

  .oa-wrap {
    margin: 0 0 10px;
  }

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]) {
    background: #f9f9f9;
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: #f9f9f9;
  }

  .mat-expansion-panel {
    box-shadow: none;
    position: relative;
    overflow: visible;
    margin: 0px 0 0;

    &.mat-expanded {
      box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
      margin-bottom: 4px;
    }
  }

  .mat-expansion-panel-header {
    height: auto;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 0 5px 30px;
    border-bottom: 1px solid #E6E6E6;
    border-radius: 0;
    background-color: transparent;

    &.mat-expanded {
      background-color: #f9f9f9;

      .mat-expansion-indicator {
        top: 6px;
      }
    }

    .mat-content {
      flex: 0 0 auto;
    }

    .mat-expansion-indicator {
      position: absolute;
      left: 12px;
      top: 1px;
    }

    .mat-expansion-indicator::after {
      border-width: 0 1.5px 1.5px 0;
      padding: 2px;
    }
  }
}

.price-main {

  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: transparent;
  }

  .mat-expansion-panel-header {
    height: auto;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
  }

  .mat-expansion-panel {
    box-shadow: none;
  }

  .mat-expansion-panel-content {
    visibility: visible;
    border: 1px solid #E6E6E6;
    margin: 2px 5px 7px;
    border-radius: 0 0 6px 6px;
  }
}

.blended-main {
  .mat-accordion {
    margin-bottom: 5px;
    position: relative;
  }

  .mat-expansion-indicator::after {
    border-width: 0 0px 1px 0 !important;
    padding: 5px !important;
    transform: none !important;
    vertical-align: super !important;
    border-color: #fff;
  }

  .mat-expansion-panel-header {
    background-color: #2F68B1;
    height: 30px !important;
    width: 60%;
    border-radius: 5px 11px 0 0 !important;
    position: relative;
    z-index: 0;
    font-size: 13px;
    padding: 0 15px 0 40px !important;
  }

  .mat-expansion-panel-header::after {
    content: "";
    border-bottom: 22px solid #2F68B1;
    border-right: 13px solid transparent;
    height: 0;
    width: 15px;
    position: absolute;
    right: -13px;
    z-index: -1;
    bottom: 0;
  }

  .mat-expansion-panel-header-title {
    color: #fff !important;
  }

  .mat-expansion-indicator {
    transform: none !important;
    position: absolute;
    left: 15px;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
    overflow: visible !important;
    margin: 0 0 15px 0 !important;
    border-radius: 6px;
  }

  .mat-expansion-panel-content {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .mat-expansion-panel-header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important;
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover,
  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: #2F68B1 !important;
  }

  .exc {
    position: absolute;
    right: 0;
    font-size: 9px;
    color: #333333;
    font-weight: 500;
    z-index: 9;
    top: 8px;
  }
}

.btn-more-wrap {
  top: 0px;
  left: 0;
  z-index: 3;

  .btn-more {
    padding: 0px 10px 0px 5px !important;
    min-width: 30px !important;
    height: 20px;
    line-height: 20px !important;

    .mat-button-wrapper {
      line-height: initial !important;

      .icons-image {
        width: 16px;
        height: 16px;
      }
    }

    .mat-button-focus-overlay {
      background: transparent !important;
    }
  }
}

.roles-dropdown input {
  margin: 0 10px 0 0;
}

.location-wrap .mat-checkbox {
  width: calc(100% - 40px);
}

.location-wrap .mat-checkbox-layout {
  width: 100%;
  align-items: center;
}

.location-wrap .mat-checkbox-label {
  width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.location-wrap .mat-checkbox-inner-container {
  margin: 3px 8px 3px 0;
}

.region-wrap .selection .mat-expansion-panel-body {
  padding: 0;
}

.custom-table {
  .custom-table-head {
    background-color: #F6F6F6;

    .custom-table-row {
      .custom-table-cell {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .custom-table-row {
    display: flex;

    .custom-table-cell {
      font-size: 11px;
      font-weight: 500;
      color: #666666;
      padding: 2px 1px;
      text-align: center;
    }
  }

  .custom-table-cell input {
    width: 34px;
    text-align: center;
    border: 0;
    border-bottom: 1px solid #C4CACC;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
  }
}

.view-all {
  font-size: 13px;
  font-weight: 500;
  color: #555555;
}

.no-list-items {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #737373;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 5px;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #C5AC2E !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-color: #555555 !important;
  background-color: #fff !important;
}

.selected-loc-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;

    .icon-info-new {
      height: 16px;
      width: 16px;
      margin: 0 0 0 5px;
    }
  }
}

.oa-head {
  display: flex;
  justify-content: flex-end;
  background-color: #2B2B2B;
  padding: 4px 6px;
  border-radius: 2px 2px 0 0;
  margin: 0px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  position: relative;

  .oa-text {
    font-size: 10px;
    font-style: italic;
    position: absolute;
    right: 5px;
    top: 4px;
  }

  .clear-btn {
    position: absolute;
    right: 4px;
    top: -22px;
  }

  .custom-toggle-btn {
    display: flex;
    align-items: end;

    .mat-button-toggle-group {
      height: 26px;
    }

    .mat-button-toggle-label-content {
      height: 26px;
      line-height: 16px;
      font-size: 10px;
      display: flex;
      align-items: center;
      padding: 0 7px;
    }

    .mat-button-toggle-button {
      display: flex;
    }
  }
}

// .savings-main .savings-main{
//   background: url(assets/image/stacked_chart.svg);
//   background-position: center bottom;
//   background-size: cover;
// }
.savings-main {
  margin: 10px 0 0 0;

  .region-overlay {
    display: flex;
  }
}

.savings-main {
  .mat-tab-label {
    background-color: #E8E8E8;
    border-radius: 4px 8px 0 0;
    font-size: 13px;
    color: #555555;
    font-weight: 500;
    opacity: 1;
    height: 32px !important;
    padding: 0 20px !important;

    &.mat-tab-label-active {
      background-color: #C5AC2E;
      color: #fff;
      border-radius: 4px 14px 0 0;
    }

    &:nth-child(1) {
      z-index: 1
    }

    &:nth-child(2) {
      margin-left: -15px;
      z-index: 0;
      padding: 0 20px 0 25px !important;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border: 0;
  }

  .section {
    border: 1px solid #C5AC2E;
    border-radius: 0 0 4px 4px;
    height: calc((100vh - 270px)/(20/9));
    overflow: auto;
  }
}

.price-main {
  .tower-analysis {
    .mat-tab-label {
      display: block;
      line-height: 24px;
    }
  }

  .mat-tab-label {
    background-color: #E8E8E8;
    border-radius: 4px 8px 0 0;
    font-size: 13px;
    color: #555555;
    font-weight: 500;
    opacity: 1;
    height: 32px !important;
    padding: 0 20px !important;
    display: none;

    &.mat-tab-label-active {
      background-color: #C5AC2E;
      color: #fff;
      border-radius: 4px 14px 0 0;
    }

    &:nth-child(1) {
      z-index: 1
    }

    &:nth-child(2) {
      margin-left: -15px;
      z-index: 0;
      padding: 0 20px 0 25px !important;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border: 0;
  }

  .section {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    height: calc(100vh - 53.5vh);
    overflow: auto;
    margin-top: 22px;
  }
}

.tower-analysis-tab {
  .mat-tab-label {
    background-color: #F2F2F2;
    font-size: 12px;
    color: #555555;
    opacity: 1;
    height: 24px !important;
    padding: 0 10px !important;
    border-radius: 0;

    &.mat-tab-label-active {
      background-color: #555555;
      color: #fff;
      border-radius: 2px 2px 0 0;
    }
  }

  .mat-tab-header {
    margin: 0 0 0px 0;
  }

  .mat-tab-header-pagination {
    padding: 0 !important;
    min-width: 20px !important;
  }
}

.tower-variance-card {
  padding: 0 !important;
  box-shadow: none !important;

  .region-overlay {
    display: flex !important;
  }
}

.oa-selections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-control {
    height: 16px;
    margin: 5px 0 0px 0px;
    width: 100px;
    border-radius: 4px;
    border-radius: 4px;
    font-size: 12px;
    padding: 5px;
  }

  .custom-select {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 10px 0px 0;
    padding: 0 10px 0 0;

    .mat-form-field-appearance-legacy .mat-form-field-infix {
      min-width: 90px;
      width: 90px;
    }

    label {
      font-size: 10px;
      font-weight: 500;
      margin-right: 5px;
      color: #fff;
    }
  }

  .custom-select-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px 0px 20px;
    padding: 0 10px 0 0;

    label {
      font-size: 10px;
      font-weight: 500;
      color: #fff;
      margin-right: 5px;
    }
  }
}

// ------niky----
.location-name-h {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: $text_white;
  padding: 7px 5px 7px 10px !important;

  input {
    background-color: transparent;
    width: auto;
    outline: none;
    border: 1px solid gray;
    position: relative;
    color: $text_light_gray;
    padding-left: 5px;
    background-image: url('./assets/image/general-price-bar/search.png');
    background-position: right center;
    background-repeat: no-repeat;
    border-bottom: 1px solid #fff;
    margin: 0 0 0 10px;
  }
}

.checked-loaction-name {
  font-size: 12px;
  font-weight: 500;
  color: $text_white;
}

.search-content {
  font-size: 13px;
  color: #555555;
  font-weight: 500;
}

.inner-table-wrap {
  margin: 0px 20px 20px;

  .inner-table-title {
    font-size: 11px;
    font-weight: 500;
    color: #2B2B2B;
    padding: 5px;
    text-align: center;
  }
}

.ag-full-width-row {
  background-color: #fff !important;
}

.ag-row-group-expanded {
  background-color: #C5AC2E;
}

.scatter-hex {
  font-style: italic;
  color: #555555;
  display: inline-block;
  padding-right: 10px;
}

.hex-role-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 0 2px;

  .editbutton-col {
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    padding-right: 4px;

    .price-hex {
      font-size: 10px;
      font-style: italic;
      color: #555555;
      padding: 0 20px;
    }

    button {
      // width: 20px;
      height: 20px;
      border: 0px solid transparent;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      background: transparent;

      .icons-image {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.row.dark {
  border: 1px solid #e8e8e8;
  border-bottom: none;
}

.row.dark {
  border: 1px solid #e8e8e8;
  border-bottom: none;
}

.tscroll {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
  height: calc(55vh - 245px);
  border-radius: 0 0 5px 5px;
  border: 1px solid #e8e8e8;
  border-top: none;

  .loc-gray {
    background-color: #fff !important;
  }

  table {
    margin: 0;
    width: 100%;
    vertical-align: middle;

    thead {
      tr {
        position: sticky;
        z-index: 2;
        color: #000;
        height: 32px;
        top: 0;

        td {
          color: #000 !important;
        }
      }

      td:first-child {
        color: #000;

      }
    }

    .sub-header-td {
      // min-width: 203px !important;
      text-align: center;
      font-size: 13px;
      padding: 5px;
    }

    .loc-row {
      // position: sticky;
      top: 0px;
      background-color: #fff;
      z-index: 99;
    }
  }

  .table>:not(caption)>*>* {
    border-bottom-width: 0px;
    padding: 0;
    box-shadow: none;
  }

  .location-name-h {
    position: sticky;
    left: 0;
    background-color: #fff;
    padding: 5px 5px 5px 10px !important;
  }

  .search-content {
    //position: sticky;
    left: 0;
    background-color: #fff;
    //z-index: 1;
    padding: 0 10px !important;
    min-width: 200px;
    max-width: 200px !important;
    border-right: 1px solid #ccc;
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid #e9dfac;
    border-radius: 0;
    width: 60px;
    background-color: transparent;
    text-align: center;
    width: calc(100% - 20px) !important;
  }

  .mat-checkbox-inner-container {
    margin-top: 3px;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .role-label {
    align-items: start;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 15px;
    margin-right: 10px;
  }

  .rounded-pill {
    background-color: #f2f2f2 !important;
    color: #000 !important;
    font-weight: normal;
    margin: 2px;
  }
}



.tscroll tr:first-child .search-content {
  border-right: none;
}

.tscroll .color-Indian,
.tscroll .color-Blended {
  background-color: #fff !important;
}

.tscroll .sub-header-td.color-Indian,
.tscroll .sub-header-td.color-Blended {
  background-color: #F5F5F5 !important;
  color: #2B2B2B;
}




.role-level-price-bar {
  .tscroll {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 0px;
    height: calc(100vh - 187px);
  }

  .towerNameHead {
    /* display: none; */
    color: #565656;
  }
}

.blended-level-price-bar {
  .thead-search {
    display: none;
  }
}


.custom-toggle {
  .mat-slide-toggle-bar {
    background-color: transparent !important;
    border: 2px solid #000;
    width: 29px !important;
    height: 16px !important;

    .mat-slide-toggle-thumb-container {
      top: 2px !important;
      left: 3px !important;

      .mat-slide-toggle-thumb {
        background-color: #000;
        width: 7px !important;
        height: 7px !important;
      }
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      border: 2px solid #C5AC2E;

      .mat-slide-toggle-thumb-container {
        left: -1px !important;

        .mat-slide-toggle-thumb {
          background-color: #C5AC2E;
        }
      }
    }

  }

  .mat-slide-toggle-ripple {
    display: none;
  }
}

.clear-btn {
  // display: flex;
  margin: 2px 0 0 2px;

  .icon-reset {
    height: 16px;
    width: 16px;
    background-position: -538px -7px;
  }
}

.region-content {
  position: relative;
}

.region-content:hover .region-overlay {
  display: flex;
}

.region-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #E8E8E8;
  border-radius: 4px;

  &.opc-1 {
    background-color: #fff;
  }

  a {
    font-size: 20px;
    font-weight: 300;
    color: #555555;
    text-align: center;
    padding: 20px;
  }
}

.ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 30px !important;
  font-size: 14px;
  padding: 30px;
  font-weight: 300;
  color: #555555;
  font-family: 'Open Sans', 'Inter', 'Roboto';
}

.mat-option {
  height: 30px !important;
  font-size: 12px !important;
  color: #555;
}

.full-width-grid {
  .ag-root-wrapper {
    border: 0;
  }

  .ag-header {
    border: 0;
  }

  .ag-row {
    border: 0;

    &.ag-row-odd {
      background-color: #F8F8F8;
    }
  }

  .ag-body-viewport {
    height: 150px;
    overflow: auto;
  }

  .ag-header-row {
    background-color: #F2F2F2;
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 600;
    // width: 100% !important;
  }

  .ag-cell,
  .ag-header-cell {
    text-align: center;
    background-color: transparent;
    border: 0;
    border-right: 1px solid #fff;

    &.ig-location {
      justify-content: left;
      text-align: left;
      padding: 0 10px;
      border-right: 1px solid #E8E8E8;

      .ag-header-cell-label {
        justify-content: left;
        text-align: left;
      }
    }

    &.ig-total-spent {
      background-color: #FDFCF2;

      input {
        width: 80%;
      }
    }

    &.ig-total-fte {
      border-right: 1px solid #F5F5F5;
    }

    .ag-header-cell-label {
      justify-content: center;
    }

    &.ag-cell-focus {
      border: 0 !important;
      height: 30px !important;
      box-shadow: none !important;
    }

    .ag-input-wrapper {
      justify-content: center;

      .ag-input-field-input {
        border: 0 !important;
        border-bottom: 1px solid #C5AC2E !important;
        border-radius: 0;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  .ag-cell {
    padding: 0;
  }

  .ag-cell-inline-editing .ag-input-field-input {
    width: 100px;
    //flex: 0 0 100px;
    padding: 5px;
    background-color: transparent;
  }

  // .ag-header-container,
  // .ag-center-cols-container {
  //   width: 100% !important;
  // }
}

.custom-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;

  .help-section {
    width: 385px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #fff;

    .bg-heading-gray {
      background-color: #555555;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 7px 10px 7px 15px;

      a {
        display: flex;
      }
    }

    .bg-heading-left-align {
      justify-content: flex-start;
      padding: 5px 35px;
      min-height: 54px;
    }

    .icon-info {
      flex: 0 0 28px;
    }
  }

  .timeline {
    position: relative;

    .rightbox {
      height: 100%;
    }

    .rb-container {
      position: relative;
    }

    .rb-container ul.rb {
      margin: 30px 15px 0 0;
      padding: 0;
      display: inline-block;
      height: calc(100vh - 335px);
      overflow: auto;
    }

    .rb-container ul.rb li {
      list-style: none;
      margin: auto;
      margin-left: 50px;
      min-height: 50px;
      border-left: 1px dashed #C5AC2E;
      padding: 0 15px 30px 15px;
      position: relative;
    }

    .rb-container ul.rb li:last-child {
      border-left: 0;
    }

    .rb-container ul.rb li::before {
      position: absolute;
      left: -8px;
      top: 0px;
      content: " ";
      height: 14px;
      width: 14px;
      background: url('assets/img/icon-dot-circle.svg');
    }

    ul.rb li .timestamp {
      color: #2B2B2B;
      position: relative;
      font-size: 12px;
      font-weight: 600;
    }

    .item-title {
      color: #555555;
      font-size: 10px;
      margin: 5px 0 0 0;

      a {
        color: #C5AC2E;
        text-decoration: underline !important;
      }
    }

    &.help-timeline {
      .rb-container ul.rb li {
        border-bottom: 1px solid #F2F2F2;
        margin: 0 10px 15px 80px;
        padding-bottom: 15px;
        border-left: 0;
        padding-left: 0;
        padding-right: 10px;
      }

      .rb-container ul.rb li::before {
        left: -40px;
        background: url('assets/image/banner-arrow-btn.svg');
        background-size: 32px;
        height: 32px;
        width: 32px;
        background-position: top;
        background-repeat: no-repeat;
      }

      .rb-container ul.rb {
        height: calc(100vh - 180px);
      }
    }
  }
}

.scatter-chart-section {
  border-radius: 4px;
  padding: 10px;

  .apexcharts-legend {

    left: 50% !important;
    transform: translateX(-50%);
    right: inherit !important;
  }

  .apexcharts-legend-series {
    margin-bottom: 0 !important;
    // margin-top: 0 !important;
  }
}

.scatter-tooltip {
  list-style: none;
  margin: 0;
  padding: 7px 12px 5px;
}

.loader-modal {
  .mat-dialog-container {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.loader {
  width: 60px;
}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.5) !important;
}

.mat-dialog-container {
  font: normal normal 600 15px/28px 'Open Sans';
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 4px !important;

  .saveSenarioContent {
    padding: 0;
    margin: 0;
    overflow: inherit;
    max-height: inherit;
  }

  .saveSenarioTitle {
    font: normal normal 600 15px/28px 'Open Sans';
    letter-spacing: 0px;
    color: #fff;
    background-color: #555555;
    padding: 5px 25px !important;
    margin-bottom: 0;
  }

  .saveSenarioSelectLabel {
    font: normal normal normal 12px/20px 'Open Sans';
    color: #2B2B2B;
    background-color: #F2F2F2;
    padding: 5px 25px;
    display: block;
    font-weight: 500;
  }

  .saveSenarioSelection {
    display: flex;
    flex-direction: column;
    padding: 25px;
    font-weight: 500;
    // min-height: 40vh;  
  }

  .saveSenarioRadio {
    .mat-radio-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
    }

    .mat-radio-group .mat-radio-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
    }
  }

  .saveSenarioInfoText {
    font: normal normal normal 12px/22px 'Open Sans';
    color: #555555;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  .saveSenariobtn {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      background: transparent;
      color: #555555;
      border: 2px solid #555555;
    }

    button.themebg {
      background: #C5AC2E;
      color: #fff;
      border: 2px solid #C5AC2E;
    }

    button.mat-button-disabled {
      background: #ebdfa5;
      color: rgba(0, 0, 0, 0.26);
      border: 2px solid #ebdfa5;
    }
  }

  .saveSenarioInput .mat-form-field {
    width: 75%;
    margin-top: 15px;
  }

  .saveSenarioInput .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
  }

  .saveSenarioInput .mat-form-field-label-wrapper {
    top: -1.3em;
  }

  .saveSenarioInput .mat-form-field-subscript-wrapper {
    margin-top: 1em
  }

  .saveSenarioInput .mat-form-field-wrapper {
    padding-bottom: 15px;
  }

  .saveSenarioRadio .mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #C5AC2E;
  }

  .saveSenarioRadio .mat-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #C5AC2E;
  }

  .saveSenarioInput .saveScenarioSelectField .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0;
  }

  .saveSenarioInput .saveScenarioSelectField .mat-form-field-infix {
    border-top: 0;
    padding: 1em 0;
    color: #bbb;
  }

  .saveSenarioInput .saveScenarioSelectField .mat-select-placeholder {
    color: #bbb;
    font-size: 12px;
  }

  .saveScenarioInputField label {
    font-size: 12px;
    padding: 0.5em 0 0em 0;
    color: #bbb;
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #d9bd10;
}


.resetModal {
  .tscroll {
    border-radius: 5px;
    height: auto;
    max-height: calc(74vh - 207px);
  }

  .mat-dialog-actions {
    margin-bottom: 0;
    padding: 8px 25px;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 25px;
  }

  .mat-form-field-flex {
    border: 1px solid #ccc;
    padding: 0px 15px;
    min-width: 400px;
    border-radius: 5px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0);
  }

  table td {
    font-size: 12px;
    color: #555555;
  }

  table tr td:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  table tr th:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    width: 30% !important;
  }

  thead tr {
    background-color: #555555;
    height: inherit;
  }

  thead th {
    color: #fff !important;
    padding-left: 25px;
  }

  .mat-chip {
    font-size: 12px;
  }

  .mat-chip-list-wrapper {
    margin: 0;
  }

  .mat-form-field-infix {
    border-top: none;
    padding: 1em 0 !important;
    font-size: 12px;
  }

  .mat-select-value {
    color: #bbb;
  }
}


.app-detailed-analysis-modal .ag-header-cell {
  border-right: 2px solid #fff;
}

.app-detailed-analysis-modal .full-width-grid .ag-header-row {
  font-size: 12px;
}

.app-detailed-analysis-modal .ag-header-cell,
.app-detailed-analysis-modal .ag-header-group-cell {
  padding-left: 5px;
  padding-right: 5px;
}

.app-detailed-analysis-modal .ag-cell-value {
  border-right: 2px solid #fff;
}

.app-detailed-analysis-modal .ag-cell-value:last-child {
  border-bottom: 1px solid #e9dfac;
}

.app-detailed-analysis-modal .ag-cell-normal-height.ig-location {
  border-bottom: none;
}

.app-detailed-analysis-modal .ag-cell-normal-height {
  border-bottom: 1px solid #e9dfac;
}

.app-detailed-analysis-modal .ig-total-fte,
.app-detailed-analysis-modal .ig-total-spent {
  border-bottom: none;
}

.app-detailed-analysis-modal .main-content-wrap {
  padding: 15px;
}

.custom-tab .regionchart-legends {
  display: block !important;
}

.inner-div-dashbord .region-main #map {
  width: 70% !important;
  text-align: left;
}



@media (max-width: 1440px) {
  .blue-bg-div.dashboard-bg {
    height: calc(100vh - 445px);
  }

  .region-main #map {
    width: 50% !important;
  }

  .oa-selections {
    .custom-select {
      margin: 0 5px 0px 0;
      padding: 0 5px 0 0;
    }

    .custom-select-filter {
      margin: 0 5px 0px 0;
      padding: 0 5px 0 0;
    }
  }

  .slider-bg .col-left .carousel-left .logo {
    padding: 0px 0 60px;
  }

  .slider-bg .col-left .carousel-left p {
    min-height: 150px;
  }

  .slider-bg .login-btn {
    margin: 60px 0 0;
  }
}

.dark {
  background-color: #2B2B2B;
}

.thead-search {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  padding-top: 0px;

  .mat-button-wrapper i {
    margin-top: 6px;
  }

  .material-icons {
    font-size: 20px;
  }

  input {
    border-bottom: 1px solid #fff !important;
    background: transparent;
    border: 0;
    padding: 2px;
    color: #fff;
  }

  :focus {
    outline: none !important;
    color: #fff;
    border: none;
  }

  ::placeholder {
    color: #ccc;
  }
}

.mat-tooltip {
  // to make possible place arrow pseudo element outside tooltip with absolute positioning
  overflow: visible;
  position: relative;
  background: #fff;
  padding: 15px !important;
  font-weight: 400;
  font-size: 12px;
  box-shadow: 0 3px 10px #aaa;
  color: #000 !important;
}

.theme-btn {
  font-size: 13px;
  background: #ccc;
  padding: 6px 15px;
  border-radius: 50px;
  display: inline-flex;
  float: right;
  cursor: pointer;

  &:hover {
    color: #fff !important;
  }
}

.theme-bg {
  background-color: #C5AC2E;
  color: #fff;
}

.carousel-center {
  .theme-btn {
    position: absolute;
    bottom: 0;
    right: 15px;
  }
}

.pyr-main {
  .custom-table {
    .table-body {
      max-height: 90px;
      overflow: auto;
    }
  }
}



.mat-form-field.mat-focused .mat-form-field-label {
  color: #c5ac2e !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #c5ac2e !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #c5ac2e !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c5ac2e !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #c5ac2e !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}

@media (min-width: 1599.99px) {
  .pyr-main {
    .custom-table {
      .table-body {
        max-height: 120px;
        overflow: auto;
      }
    }
  }
}

@media (min-width: 1899.99px) {
  .savings-main {
    margin-top: 35px;
  }

  .pyr-main {
    .custom-table {
      .table-body {
        max-height: 135px;
        overflow: auto;
      }
    }
  }

  .bechmark-spend-saving-modal-box .apexcharts-toolbar {
    top: 0 !important;
  }
}






.popover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  z-index: 9999;
  left: 5px !important;
  position: absolute;
  width: 300px;

  ul {
    margin-left: 0;
    padding-left: 10px;
  }

  .popover-arrow {
    position: absolute;
    z-index: 999;
  }

  .popover-header {
    background-color: transparent;
    color: #2b2b2b;
  }
}

.notetext {
  font-size: 10.5px;
  position: absolute;
  bottom: -24px;
  right: 5px;
  z-index: 9;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: end;
  width: 100%;
  font-weight: 400;
  align-items: center;

  div {
    margin-left: 20px;
  }
}

.shepherd-text {
  font-size: 13px;
  font-family: 'Open Sans';
  font-weight: 500;
}

.number-align-right {
  text-align: right !important;
  padding-right: 10px !important;
}

.cbtn-secondary-2 {
  margin: 0;
  background-color: #C5AC2E;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  margin-right: 5px;
}

.shepherd-footer .cbtn-secondary-2:hover {
  background-color: #C5Ab2E;
}

.shepherd-title {
  font-size: 13px;
  font-weight: 600;
  color: #555;
  padding: 3px 10px;
  border-bottom: 1px solid #E8E8E8;
}

.shepherd-header {
  padding: 5px !important;
}

.shepherd-text {
  border-bottom: 1px solid #E8E8E8;
}

.shepherd-footer {
  background-color: #f3f3f3;
  padding-top: 6px;
}

.roles-table .ag-header-cell-label {
  justify-content: center;
}

.roles-table .ag-pinned-left-header .ag-header-cell-label {
  justify-content: center;
}

// .roles-table .ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-last-left-pinned.oat-total-spend {
//   text-align: right;
//   padding-right: 15px;
// }

.custom-slider~.title {
  color: rgba(55, 61, 63) !important;
  font-weight: 900 !important;
  font-size: 12px !important;
}

.ag-theme-alpine {
  --ag-range-selection-border-style: "";
}

.custom-tab .btns-wrap .btn-prev {
  background-color: #fff;
  border: 1px solid #555;
  background: url(assets/image/stepprocess_button_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;

  i {
    display: none;
  }
}

.toggle-benchmark-type.mat-menu-panel {
  min-height: auto;
}

.selectAllTower {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -10px;
  flex-wrap: nowrap;
}

.selectAllTower .selectAllTowerOne {
  margin-right: 33px;
}

// .mat-dialog-content  .category-main .selectAllTower .selectAllTowerOne{
//   margin-right: 5px;
// }
// .custom-tab  .category-main .selectAllTower .selectAllTowerOne{
//   margin-right: 5px;
// }



// .dealParameterSelection {
//   align-items: center;   
//   .dealParameterName {
//       font-size: 12px;
//       color: #555;
//   }
//   .mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper{
//     transform: translateY(0%);
//   }
//   .dealParameterSelect{
//       font-size: 12px;
//       color: #555;  
//       .mat-form-field-wrapper{          
//           padding-bottom: 0;
//           padding: 0 10px;
//           margin-bottom: 8px;
//           border-radius: 3px;
//       }  
//       .mat-form-field-flex{
//         border: 1px solid #ccc;
//         padding: 0px 15px;
//         min-width: 400px;
//         border-radius: 5px;
//       }   
//       .mat-form-field-infix{
//         border-top: none;
//         padding: 0.75em 0;
//         font-size: 12px;
//       }
//       .mat-form-field-underline{
//         display: none;
//       }
//       .mat-select-arrow{
//         height: 0;
//       }

//   }
// }

.dealSelectedValue {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .dealParameterSelection {
    align-items: center;

    .dealParameterName {
      font-size: 12px;
      color: #555;
      max-width: 93px;
      text-align: center;
      margin: 0 auto 10px auto;
      line-height: 1.3;
    }

    .mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
      transform: translateY(0%);
    }

    .dealParameterSelect {
      font-size: 12px;
      color: #555;
      text-align: center;

      .mat-form-field-wrapper {
        padding-bottom: 0;
        padding: 0px;
        margin-bottom: 8px;
        border-radius: 3px;
      }

      .mat-form-field-flex {
        border: 1px solid #c5ac2e;
        padding: 0px 2px 0 10px;
        max-width: 130px;
        border-radius: 25px;
        background-color: #c5ac2e;
      }

      .mat-form-field-infix {
        border-top: none;
        padding: 0;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-select-arrow {
        height: 0;
        color: #fff;
      }

      .mat-select-value {
        color: #fff;
        text-align: center;
      }
    }
  }
}

.tower-params-component {
  .mat-dialog-content {
    overflow: hidden;
  }

  .mat-select-arrow-wrapper {
    margin-top: 10px !important;
  }

  .disp-flex {
    border-radius: 6px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 500px;
    min-height: 200px;
  }

  .disp-flex {
    border-radius: 6px;
    overflow: auto;
  }

  .custom-select {
    margin: 0;
    padding: 5px;

    .mat-form-field-appearance-legacy .mat-form-field-infix {
      min-width: 100%;
      border: none;
    }
  }

  .table-sm>:not(caption)>*>* {
    padding: 0;
  }

  .table {
    margin-bottom: -1px;
    font-size: 12px;

    thead {
      position: sticky;
      top: 0;
      z-index: 9;
    }

    td {
      padding: 5px 10px;
    }

    th {
      padding: 10px 5px;
      width: 196px;
      background: #555;
      color: #fff;
      font-weight: 400;
    }
  }

  .mat-dialog-actions {
    background-color: #F3F3F3;
    padding: 0 15px 0px;
    min-height: 55px;
    margin-bottom: 0px;
  }

  .saveSenariobtn button.mat-button-disabled {
    background: #d2bf5e;
    color: rgba(0, 0, 0, 0.26);
    border: 2px solid #d2bf5e;
  }

}

.mat-slider-track-wrapper {
  display: none;
}

.mat-slider-thumb {
  width: 12px;
  border-radius: 50%;
  height: 12px;
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: red;
  height: 12px;
  border-radius: 50%;
}

.mat-slider:not(.mat-slider-disabled):not(.mat-slider-sliding) .mat-slider-thumb-label,
.mat-slider:not(.mat-slider-disabled):not(.mat-slider-sliding) .mat-slider-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  border-inline: $bg_white;
  cursor: default !important;

}

.quartilePanel {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .quartilePanelText {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 12px;
    flex-direction: column;
    padding-left: 10px;
  }

  .quartilePanelText div {
    margin-right: 25px;
  }
}


.detail-tower-modal .mat-dialog-content {
  min-height: 75vh;
}

.region-main .regionLocation-wrap .col-lg-4,
.region-main .regionLocation-wrap .col-md-4 {
  width: 41.666%;
}

.region-main .regionLocation-wrap .col-lg-8,
.region-main .regionLocation-wrap .col-md-8 {
  width: 58.31%;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 1px !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: #fff !important;
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: #8bc34a !important;
}


.mat-tooltip {
  background: #fff !important;
  color: #000 !important;
}

.mat-menu-panel {
  min-height: auto !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #C5AC2E !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: 0 0 0 2px rgb(224 212 143) !important;
}

.displayLoader {
  background: url('assets/img/dark-loader.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}

.red-text {
  color: #FF0000;
}

.mat-dialog-content .customFlex div:first-child span {
  width: 135px;
}


div:where(.swal2-container) div:where(.swal2-popup) {
  padding: 1em 0 1.25em !important;
  width: auto !important;
  min-width: 26em !important;
  max-width: 37.5em !important;
}

div:where(.swal2-container) .swal2-html-container {
  position: relative;
  color: #545454 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: justify !important;
  margin: 0.5em 1.6em .3em !important;
  margin-left: 4em !important;


}

div:where(.swal2-container) h2:where(.swal2-title) {
  display: none !important;
}

div:where(.swal2-icon) {
  position: absolute !important;
  width: 20px !important;
  height: 20px !important;
  margin: 1.5em auto 0.6em !important;
  left: 20px !important;
  top: 0px;
  border-width: 2px !important;
}

div:where(.swal2-icon) .swal2-icon-content {
  font-size: 14px !important;
}

iv:where(.swal2-icon).swal2-success .swal2-success-ring {
  top: -0.21em !important;
  left: -0.21em !important;
  border: 2px solid rgba(165, 220, 134, .3) !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
  height: 2px !important;
  border-radius: 0.125em !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=long] {
  top: 9px !important;
  right: 2px !important;
  width: 12px !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
  top: 11.8px !important;
  left: 2px !important;
  width: 7px !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line] {
  display: none !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: 0 !important;
  left: 0 !important;
  border-radius: 2.5em 0 0 2.5em !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-fix {
  top: 0.5em !important;
  left: 20.625em !important;
  width: 3px !important;
  height: 4px !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: 0.3125em !important;
  left: 100px !important;
  border-radius: 0 2.6em 2.6em 0 !important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  top: 9px !important;
  width: 11px !important;
  height: 2px !important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 5px !important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 4px !important;
}

div:where(.swal2-icon).swal2-warning {
  border-color: #c5ac2e !important;
  color: #c5ac2e !important;
}

div:where(.swal2-icon).swal2-question {
  border-color: #9de0f6 !important;
  color: #3fc3ee !important;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  font-size: 14px !important;
  margin: 0.6em auto 0 !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  padding: 5px 10px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  font-size: 14px !important;
}

.label-align-now .ag-header-cell-label {
  justify-content: left !important;
}


.label-align-RU .ag-header-cell-label {
  justify-content: left !important;
  padding-left: 12px;
}

.error-text {
  font-size: 14px;
  font-weight: 300;
  color: #555555;
}

.ag-overlay-no-rows-wrapper span {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #555555 !important;
}

.parent-dropdown.mat-menu-item {
  background-color: #F5F5F5;
}

.only-break-word-tooltip {
  word-break: keep-all;
  white-space: pre-line;
  max-width: unset !important;
}

.mat-typography h2.Variance-Spread-Desc {
  font-size: 13px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 0;
  padding-left: 10px;

}